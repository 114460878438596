import React from "react";
import "./PagePdf.css";

function PagePdf({ children, currentPage, totalPages, logos, innerHTML }) {
  const showSeparator = currentPage !== undefined && totalPages !== undefined && currentPage >= 2;

  return (
    <div className='page-pdf-container' custom-page-number={currentPage}>
      <div className={`header-pdf ${currentPage > 1 ? "" : "d-none"}`}>
        <img src={logos?.header} />
      </div>
      {innerHTML ? (
        <div className='page-pdf' dangerouslySetInnerHTML={{ __html: innerHTML }} />
      ) : (
        <div className='page-pdf'>{children}</div>
      )}
      <div className='footer-pdf'>
        <img src={logos?.footer} />
        <div className={`page-number ${currentPage > 1 ? "" : "d-none"}`}>
          {showSeparator && `Page ${currentPage}/${totalPages}`}
        </div>
      </div>
    </div>
  );
}

export default PagePdf;
