import React, { useContext, useState, useEffect, useRef } from "react";
import "./CreateLivrableModal.css";
import AppContext from "../../../../../context/AppContext";
import { set, useForm } from "react-hook-form";
import Input from "../../../../molecules/formComponents/input/Input";
import { defaultValidation } from "../../../../../utils/formValidation/FormValidation";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import { createDeliverableApi } from "../../../../../api/FolderApi";
import { toggleCollapseElement } from "../../../../../utils/Utils";
import Bracket from "../../../../atoms/icons/general/bracket/Bracket";

function CreateLivrableModal({ folder, folderId, onLivrableCreated = () => {} }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { setModalVisible, createNotification } = useContext(AppContext);
  const [selectedEstimations, setSelectedEstimations] = useState([]);
  const [folderEstimations, setFolderEstimations] = useState([]);
  const [selectedFinancingPlans, setSelectedFinancingPlans] = useState([]);
  const [folderFinancingPlans, setFolderFinancingPlans] = useState([]);
  const [folderCreditSimulators, setFolderCreditSimulators] = useState([]);
  const [selectedCreditSimulators, setSelectedCreditSimulators] = useState([]);
  const [openedCategory, setOpenedCategory] = useState(0);

  const estimationsCategoryRef = useRef(null);
  const financingPlansCategoryRef = useRef(null);
  const creditSimulatorsCategoryRef = useRef(null);

  useEffect(() => {
    if (folder?.estimations) {
      setFolderEstimations(folder.estimations);
    }

    if (Array.isArray(folder?.financing_plans)) {
      setFolderFinancingPlans(folder.financing_plans);
    } else {
      setFolderFinancingPlans([]);
    }

    setFolderCreditSimulators(folder.credit_simulators);

    setTimeout(() => {
      if (folder?.estimations.length) toggleOpenedCategory(openedCategory, 1);
      else if (folder?.financing_plans.length) toggleOpenedCategory(openedCategory, 2);
      else if (folder?.credit_simulators.length) toggleOpenedCategory(openedCategory, 3);
    }, 100);
  }, [folder]);

  function toggleEstimationSelection(estimation) {
    if (selectedEstimations.includes(estimation)) {
      setSelectedEstimations(selectedEstimations.filter(e => e.id !== estimation.id));
    } else {
      setSelectedEstimations([...selectedEstimations, estimation]);
    }
  }

  function toggleFinancingPlanSelection(financingPlan) {
    if (selectedFinancingPlans.some(e => e.financing_plan_id === financingPlan.financing_plan_id)) {
      setSelectedFinancingPlans(
        selectedFinancingPlans.filter(e => e.financing_plan_id !== financingPlan.financing_plan_id),
      );
    } else {
      setSelectedFinancingPlans([...selectedFinancingPlans, financingPlan]);
    }
  }

  function toggleCreditSimulatorSelection(creditSimulator) {
    if (selectedCreditSimulators.some(e => e.credit_id === creditSimulator.credit_id)) {
      setSelectedCreditSimulators(selectedCreditSimulators.filter(e => e.credit_id !== creditSimulator.credit_id));
    } else {
      setSelectedCreditSimulators([...selectedCreditSimulators, creditSimulator]);
    }
  }

  function toggleOpenedCategory(openedCategory, category) {
    const categoryRef =
      category === 1
        ? estimationsCategoryRef
        : category === 2
          ? financingPlansCategoryRef
          : creditSimulatorsCategoryRef;

    const previewCategoryRef =
      openedCategory === 1
        ? estimationsCategoryRef
        : openedCategory === 2
          ? financingPlansCategoryRef
          : creditSimulatorsCategoryRef;

    toggleCollapseElement(previewCategoryRef.current, false);

    if (openedCategory === category) return setOpenedCategory(0);

    setOpenedCategory(category);

    if (openedCategory === 0) return toggleCollapseElement(categoryRef.current, true);

    return toggleCollapseElement(categoryRef.current, true);
  }

  async function onSubmit(data) {
    const estimationIds = selectedEstimations.map(estimation => ({ id: estimation.id }));
    const financingPlanIds = selectedFinancingPlans.map(financingPlan => ({ id: financingPlan.financing_plan_id }));
    const creditSimulatorIds = selectedCreditSimulators.map(creditSimulator => ({
      id: creditSimulator.credit_id,
    }));

    try {
      const res = await createDeliverableApi(
        data.deliverableName,
        folderId,
        estimationIds,
        financingPlanIds,
        creditSimulatorIds,
      );
      createNotification(<>Livrable créé avec succès</>);
      onLivrableCreated(res.data);
      setModalVisible(false);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la création du livrable</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='create-deliverable-modal'>
      <div className='info-create-livrable'>
        <p>
          Créer un livrable pour n'importe quelle partie de l'affaire (Acquéreur, vendeur...). Vous pouvez créer autant
          de livrables que vous le souhaitez.
        </p>
      </div>
      <Input
        validation={defaultValidation}
        useForm={{ register, setValue }}
        error={errors.deliverableName?.message}
        name='deliverableName'
        label='Nom du livrable'
        placeholder='Acquéreur'
      />
      <div className='info-create-livrable mb-sm'>
        <p>
          Vous pouvez ajouter des estimations, des plans de financement et des tableaux d'amortissement à votre
          livrable.
        </p>
      </div>
      <div className='create-deliverable-modal-list'>
        {folderEstimations.length ? (
          <div className='checkbox-list-container'>
            <h3 onClick={() => toggleOpenedCategory(openedCategory, 1)}>
              Importer des estimations
              <Bracket color='var(--dark-blue)' rotation={openedCategory === 1 ? "180deg" : "90deg"} width='15px' />
            </h3>
            <div className='import-estimations-to-deliverable' ref={estimationsCategoryRef}>
              {folderEstimations.map((estimation, index) => (
                <Checkbox
                  key={estimation.id || index}
                  name={`estimation-${estimation.id}`}
                  className='estimation-checkbox'
                  label={estimation.infos.nom || estimation.infos.enseigne}
                  onChange={() => toggleEstimationSelection(estimation)}
                  checked={selectedEstimations.includes(estimation)}
                />
              ))}
            </div>
          </div>
        ) : null}
        {folderFinancingPlans.length ? (
          <div className='checkbox-list-container'>
            <h3 onClick={() => toggleOpenedCategory(openedCategory, 2)}>
              Importer des plans de financement
              <Bracket color='var(--dark-blue)' rotation={openedCategory === 2 ? "180deg" : "90deg"} width='15px' />
            </h3>
            <div className='import-financingplans-to-deliverable' ref={financingPlansCategoryRef}>
              {folderFinancingPlans.map((financingPlan, index) => (
                <Checkbox
                  key={financingPlan.financing_plan_id || index}
                  name={`financingPlan-${financingPlan.financing_plan_id}`}
                  className='financingplan-checkbox'
                  label={financingPlan.financing_plan_name}
                  onChange={() => toggleFinancingPlanSelection(financingPlan)}
                  checked={selectedFinancingPlans.some(e => e.financing_plan_id === financingPlan.financing_plan_id)}
                />
              ))}
            </div>
          </div>
        ) : null}
        {folderCreditSimulators.length ? (
          <div className='checkbox-list-container'>
            <h3 onClick={() => toggleOpenedCategory(openedCategory, 3)}>
              Importer des tableaux d'amortissement
              <Bracket color='var(--dark-blue)' rotation={openedCategory === 3 ? "180deg" : "90deg"} width='15px' />
            </h3>
            <div className='import-financingplans-to-deliverable' ref={creditSimulatorsCategoryRef}>
              {folderCreditSimulators.map((creditSimulator, index) => (
                <Checkbox
                  key={creditSimulator.credit_id || index}
                  name={`financingPlan-${creditSimulator.credit_id}`}
                  className='financingplan-checkbox'
                  label={creditSimulator.credit_name}
                  onChange={() => toggleCreditSimulatorSelection(creditSimulator)}
                  checked={selectedCreditSimulators.some(e => e.credit_id === creditSimulator.credit_id)}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' type='submit'>
          Créer le livrable
        </FilledButton>
      </div>
    </form>
  );
}

export default CreateLivrableModal;
