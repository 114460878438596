import "./Pdf.css";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import fullLogo from "../../../assets/logos/logo_emc_full_light.svg";
import fullLogoDark from "../../../assets/logos/logo_emc_full_dark.svg";
import iconLogo from "../../../assets/logos/logo_emc_icon_light.svg";
import PdfMc from "../../../components/pdfComponents/pdfMc/PdfMc";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../context/AppContext";
import {
  deleteSecondaryYearApi,
  fetchEstimationApi,
  fetchEstimationWithSecondaryYearsApi,
  generatePdfApi,
} from "../../../api/EstimationApi";
import { PdfContext } from "../../../context/PdfContext";
import { fetchDefaultVisitingCardApi } from "../../../api/VisitingCardApi";
import { useAuth } from "../../../context/AuthContext";
import { formatNumberWithSpaces, getRandomString } from "../../../utils/Utils";
import PagePdf from "../../../components/pdfComponents/pagePdf/PagePdf";
import SummaryPdf, {
  generateHrefPdf,
  getTitleWithoutNumber,
} from "../../../components/pdfComponents/summaryPdf/SummaryPdf";
import H2Pdf from "../../../components/pdfComponents/h2Pdf/H2Pdf";
import PdfFdc from "../../../components/pdfComponents/pdfFdc/PdfFdc";
import PdfTds from "../../../components/pdfComponents/pdfTds/PdfTds";
import { PdfTdsFdcProvider } from "../../../context/PdfTdsFdcContext";
import H3Pdf from "../../../components/pdfComponents/h3Pdf/H3Pdf";
import FilledButton from "../../../components/molecules/buttons/filledButton/FilledButton";
import Download from "../../../components/atoms/icons/general/download/Download";
import { minify } from "csso";
import { saveAs } from "file-saver";
import UnfilledButton from "../../../components/molecules/buttons/unfilledButton/UnfilledButton";

function Pdf() {
  const { estimationIdParam } = useParams();
  const {
    setAppLoaderVisible,
    createNotification,
    isPdfNotificationLoading,
    setIsPdfNotificationLoading,
    setModalVisible,
    setModalContent,
  } = useContext(AppContext);
  const { getUuid } = useAuth();
  const [estimationType, setEstimationType] = useState(null);
  const [mainYear, setMainYear] = useState(null);
  const [estimationData, setEstimationData] = useState([]);
  const [visitingCard, setVisitingCard] = useState(null);
  const [rapportData, setRapportData] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(1);
  const [businessImages, setBusinessImages] = useState([]);
  const [shouldRenderPdf, setShouldRenderPdf] = useState(false);
  //PDF generation
  const [summaryChapters, setSummaryChapters] = useState([]); // sommaire
  const [finalPdfContent, setFinalPdfContent] = useState([]); // contenu final du pdf
  const [finalPdfSections, setFinalPdfSections] = useState([]);

  const pdfGeneratorContainerRef = useRef(null);
  const finalPdfContainerRef = useRef(null);
  const paragraphHeightCalcRef = useRef(null);
  const summaryContainerRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  useEffect(() => {
    fetchAllData();
  }, [estimationIdParam]);

  useEffect(() => {
    if (summaryChapters.length) {
      generatePdfWithSummary(finalPdfSections);
    }
  }, [summaryChapters]);

  useEffect(() => {
    generateSummaryFromHtml(finalPdfSections);
  }, [finalPdfSections]);

  useEffect(() => {
    if (!finalPdfContent.current) return;

    addAnchorsOnTitles(finalPdfContainerRef.current);
    document.getElementsByClassName("annex-pdf-id")[1]?.setAttribute("id", "annexes");
  }, [finalPdfContent]);

  //CONFIG
  const config = {
    logos: { header: iconLogo, footer: fullLogo, cover: fullLogoDark },
    style: {
      "--main-color": "var(--dark-blue)",
      "--secondary-color": "var(--gold)",
      "--text-color": "black",
      "--secondary-text-color": "white",
      "--third-text-color": "var(--sky-blue)",
      "--titles-background": "var(--pale-blue)",
      "--default-margin": "25px",
      "--table-header": "var(--dark-blue)",
      "--table-header-text": "var(--white)",
      "--table-text": "var(--dark-blue)",
      "--table-border": "var(--grey)",
      "--table-type-light": "var(--pale-blue)",
      "--table-type-medium": "var(--gold)",
      "--dot":
        'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik02LjY2NjY4IDEwLjAwMDdDOC41MDc2MyAxMC4wMDA3IDkuOTk5OTkgOC41MDgzIDkuOTk5OTkgNi42NjcyOEM5Ljk5OTk5IDQuODI2MzYgOC41MDc2MyAzLjMzMzk4IDYuNjY2NjggMy4zMzM5OEM0LjgyNTcgMy4zMzM5OCAzLjMzMzMyIDQuODI2MzYgMy4zMzMzMiA2LjY2NzI4QzMuMzMzMzIgOC41MDgzIDQuODI1NyAxMC4wMDA3IDYuNjY2NjggMTAuMDAwN1oiIGZpbGw9IiM4OTExNDciLz4NCjxwYXRoIGQ9Ik02LjY2NjY4IDBDNS4zNDgxMyAwIDQuMDU5MiAwLjM5MDk4OSAyLjk2Mjg3IDEuMTIzNTNDMS44NjY1NSAxLjg1NjA4IDEuMDEyMDYgMi44OTcyNyAwLjUwNzQ3NyA0LjExNTQ0QzAuMDAyODg3NjQgNS4zMzM2MiAtMC4xMjkxMzQgNi42NzQwMyAwLjEyODEwMiA3Ljk2NzI3QzAuMzg1MzM5IDkuMjYwNSAxLjAyMDI4IDEwLjQ0ODQgMS45NTI2MyAxMS4zODA3QzIuODg0OTcgMTIuMzEzMSA0LjA3Mjg3IDEyLjk0OCA1LjM2NjA3IDEzLjIwNTJDNi42NTkyNyAxMy40NjI1IDcuOTk5NyAxMy4zMzA1IDkuMjE3ODggMTIuODI1OUMxMC40MzYxIDEyLjMyMTMgMTEuNDc3MiAxMS40NjY4IDEyLjIwOTggMTAuMzcwNUMxMi45NDIzIDkuMjc0MTcgMTMuMzMzNCA3Ljk4NTIzIDEzLjMzMzQgNi42NjY2OEMxMy4zMzM0IDUuNzkxMTkgMTMuMTYwOSA0LjkyNDI4IDEyLjgyNTkgNC4xMTU0NEMxMi40OTA4IDMuMzA2NiAxMS45OTk4IDIuNTcxNjggMTEuMzgwNyAxLjk1MjYyQzEwLjc2MTcgMS4zMzM1NiAxMC4wMjY4IDAuODQyNTAyIDkuMjE3ODggMC41MDc0NjlDOC40MDkwOCAwLjE3MjQzNiA3LjU0MjE4IDAgNi42NjY2OCAwWk02LjY2NjY4IDExLjMzMzNDNS43NDM3IDExLjMzMzMgNC44NDE0NCAxMS4wNTk2IDQuMDc0MDEgMTAuNTQ2OEMzLjMwNjU4IDEwLjAzNDEgMi43MDg0NCA5LjMwNTIzIDIuMzU1MjQgOC40NTI1QzIuMDAyMDIgNy41OTk3OCAxLjkwOTYxIDYuNjYxNTIgMi4wODk2NyA1Ljc1NjI0QzIuMjY5NzQgNC44NTEgMi43MTQyIDQuMDE5NDggMy4zNjY4NCAzLjM2Njg0QzQuMDE5NDggMi43MTQxOSA0Ljg1MSAyLjI2OTczIDUuNzU2MjUgMi4wODk2N0M2LjY2MTUyIDEuOTA5NiA3LjU5OTc4IDIuMDAyMDEgOC40NTI1IDIuMzU1MjNDOS4zMDUyMyAyLjcwODQ0IDEwLjAzNDEgMy4zMDY1NyAxMC41NDY4IDQuMDc0MDFDMTEuMDU5NiA0Ljg0MTQzIDExLjMzMzMgNS43NDM2OSAxMS4zMzMzIDYuNjY2NjhDMTEuMzMzMyA3LjkwNDM2IDEwLjg0MTcgOS4wOTEzNCA5Ljk2NjU0IDkuOTY2NDdDOS4wOTEzNCAxMC44NDE3IDcuOTA0MzYgMTEuMzMzMyA2LjY2NjY4IDExLjMzMzNaIiBmaWxsPSIjODkxMTQ3IiBmaWxsLW9wYWNpdHk9IjAuNiIvPg0KPC9zdmc+DQo=")',
      "fontFamily": "Outfit",
    },
    pageHeight: 910, // hauteur de la page en px
    maxRowsBySummaryPage: 20, // nombre de lignes max par page du sommaire
    chartsColor: {
      year_1: {
        border: "rgba(99,126,222, 1)",
        background: "rgba(249, 244, 254, 1)",
      },
      year_2: {
        border: "rgba(99,126,222, 1)",
        background: "rgba(235, 222, 254, 1)",
      },
      year_3: {
        border: "rgba(75, 169, 237, 1)",
        background: "rgba(167, 221, 255, 1)",
      },
      year_4: {
        border: "rgba(75, 169, 237, 1)",
        background: "rgba(214, 239, 252, 1)",
      },
      color_1: "#fe8955",
      color_2: "#f93b2a",
      color_3: "#acd9f7",
      color_4: "#fbc41d",
      color_5: "#6080de",
    },
  };

  // FETCH DATA FROM API
  async function fetchAllData() {
    setAppLoaderVisible(true);
    const unfinishedBilan = await fetchEstimation();

    if (unfinishedBilan) return preventPdfAccess(unfinishedBilan);

    setShouldRenderPdf(true);
    await fetchVisitingCard();

    setTimeout(() => {
      splitMainPageBreaks();
      setTimeout(() => {
        setAppLoaderVisible(false);
      }, 300);
    }, 2000);
  }

  async function fetchEstimation() {
    try {
      let estimationYears = [];
      const estimation = (await fetchEstimationApi(estimationIdParam, true, true)).data;

      if (estimation.estimation.type.id !== 3) {
        const years = (await fetchEstimationWithSecondaryYearsApi(estimationIdParam)).data.related_estimations;
        estimationYears.push(estimation);

        estimation.rapport_data.graphs.evolution_activite = {
          total_ca: [],
          marge_brute: [],
          valeur_ajoutee: [],
        };

        for (const year of years) {
          if (!year.validated) return year;
          estimationYears.push((await fetchEstimationApi(year.id, true)).data);
        }

        const sortedYears = sortYears(estimationYears);

        for (const year of sortedYears) {
          estimation.estimation.year = estimation.estimation.infos?.date_bilan.split("/")[2];
          estimation.rapport_data.graphs.evolution_activite.marge_brute.push(year.rapport_data.graphs.marge_brute);
          estimation.rapport_data.graphs.evolution_activite.valeur_ajoutee.push(year.rapport_data.array.valeur_ajoutee);
          estimation.rapport_data.graphs.evolution_activite.total_ca.push(
            year.rapport_data.graphs.taux_effort_masse_salariale.total_ca,
          );
        }
        setEstimationData(sortedYears.map(year => year.estimation));
      }
      setEstimationType(estimation.estimation.type.id);

      setRapportData(estimation.rapport_data);
      setMainYear(estimation.estimation);
      setImagesFromEstimation(estimation.estimation.images);
    } catch (e) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de votre estimation. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
      navigate("/" + e.response.status);
    }
  }

  function preventPdfAccess(bilan) {
    if (!bilan) return;

    setModalContent({
      title: "Estimation incomplète",
      content: (
        <>
          <p>
            Le bilan {bilan.infos.date_bilan.slice(-4)} n'a pas été complété.
            <br />
            Veuillez le compléter ou le supprimer afin d'accéder à votre rapport
          </p>
          <div className='modal-buttons-row mt-md'>
            <UnfilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                setModalContent({ title: "", content: <></> });
                showDeleteSecondaryYearModal(bilan);
              }}>
              Supprimer le bilan
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                setModalContent({ title: "", content: <></> });
                navigate(`/detail-parcours/secondaire/${bilan.id}`);
              }}>
              Accéder au bilan
            </FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  function showDeleteSecondaryYearModal(bilan) {
    setModalContent({
      title: "Supprimer le bilan",
      content: (
        <>
          <p>Voulez-vous supprimer le bilan {bilan.infos.date_bilan.slice(-4)} ? </p>
          <div className='modal-buttons-row mt-md'>
            <UnfilledButton
              padding='10px 25px'
              onClick={() => {
                setModalVisible(false);
                setModalContent({ title: "", content: <></> });
              }}>
              Annuler
            </UnfilledButton>
            <FilledButton padding='10px 25px' onClick={() => deleteSecondaryYear(bilan.id)}>
              Valider
            </FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  async function deleteSecondaryYear(bilanId) {
    try {
      await deleteSecondaryYearApi(bilanId);
      createNotification(<>Le bilan a été supprimé avec succès</>);
      createNotification(
        <>Une erreur est survenue lors de la suppression du bilan. Veuillez réessayer plus tard</>,
        "var(--red)",
        "var(--grey)",
      );
    } finally {
      setModalVisible(false);
      window.location.reload();
    }
  }

  async function fetchVisitingCard() {
    try {
      const visitingCard = (await fetchDefaultVisitingCardApi(getUuid())).data[0];

      setVisitingCard(visitingCard ?? null);
    } catch (e) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de la carte de visite</>,
        "var(--red)",
        "var(--grey)",
      );
    }
  }

  // CONVERTING IMAGE TO BASE64
  async function replaceImagesWithBase64() {
    const images = Array.from(document.querySelectorAll("img"));

    const imagePromises = images.map(async image => {
      image.src = await getBase64FromImageSrc(image.src);
    });

    await Promise.all(imagePromises);
  }

  async function getBase64FromImageSrc(src) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject; // Ajoutez une gestion des erreurs
      xhr.open("GET", src);
      xhr.responseType = "blob";
      xhr.send();
    });
  }

  // FORMATTING DATA
  function formatDataForStrongWeakPoints(data, model, setData) {
    if (!data) return [];

    const result = [];

    model.map(section => {
      const tmp = {
        title: section.title,
        content: [],
        id: section.id,
      };

      section.children.map(child => {
        const tmpChild = {
          title: child.label,
          starsCount: data[child.key].stars,
          points: [],
        };

        if (data[child.key]?.force) tmpChild.points.push({ isStrongPoint: true, content: data[child.key].force });

        if (data[child.key]?.faiblesse)
          tmpChild.points.push({ isStrongPoint: false, content: data[child.key].faiblesse });

        if (tmpChild.points.length) tmp.content.push(tmpChild);
      });

      if (tmp.content.length) result.push(tmp);
    });

    setData(result);
  }

  function formatDataForDescriptiveText(data, model, setData) {
    if (!data) return [];

    const result = [];

    model.map(section => {
      const tmp = {
        title: section.title,
        icon: section.icon,
        id: section.id,
        conclusion: data.notes[section.conclusion],
        content: [],
      };

      section.content.map(content => {
        const tmpContent = {
          id: content.id,
          title: content.label,
          isTitleBold: content.isBold,
          content: [],
        };

        tmpContent.content = content.points
          .map(point => {
            if (point.special)
              switch (point.special) {
                case "extraction":
                  return {
                    normalText: "",
                    boldText: getExtractionDescription(data),
                  };
                case "franchise":
                  return {
                    normalText: "",
                    boldText: getFranchiseDescription(data),
                  };
                case "e-reputation":
                  const boldText = getEReputationDescription(data);
                  if (!boldText) return null;
                  return {
                    normalText: "",
                    boldText: boldText,
                  };
              }

            const boldText = point.keys.reduce((acc, key) => acc?.[key], data);

            if (!boldText) return null;

            return {
              normalText: point.label,
              boldText: point.suffix ? formatNumberWithSpaces(boldText, true, point.suffix) : boldText,
            };
          })
          .filter(point => point);

        if (tmpContent.content.length) tmp.content.push(tmpContent);
      });

      if (tmp.content.length) result.push(tmp);
    });

    setData(result);
  }

  function getExtractionDescription(data) {
    if (data.normes_accessibilite) {
      if (!data.normes_accessibilite.extraction?.description) {
        return data.normes_accessibilite.extraction.description;
      }
    }
    return "Extraction non nécessaire pour l'activité";
  }

  function getFranchiseDescription(data) {
    if (data.ratios.franchise?.montant)
      return `Oui, montant de la franchise : ${formatNumberWithSpaces(data.ratios.franchise.montant, true, "€")}`;
    return "Non";
  }

  function getEReputationDescription(data) {
    let result = [];
    if (data.e_reputation.note_google) result.push("Avis Google : " + data.e_reputation.note_google + "/5");
    if (data.e_reputation.note_tripadvisor)
      result.push("Avis Trip Advisor : " + data.e_reputation.note_tripadvisor + "/5");
    if (data.e_reputation.note_the_fork) result.push("Avis The Fork : " + data.e_reputation.note_the_fork + "/10");
    if (data.e_reputation.note_booking) result.push("Avis Booking : " + data.e_reputation.note_booking + "/10");
    if (data.e_reputation.note_autre) result.push("Autres : " + data.e_reputation.note_autre + "/5");

    return result.length ? result.join(", ") : null;
  }

  function setImagesFromEstimation(images) {
    if (!images) return;
    const result = [];

    for (const image of Object.values(images)) {
      result.push(image);
    }
    setBusinessImages(result.filter(image => image));
  }

  // FORMATTING PDF PAGES
  function getHTMLElementsHeight(...elements) {
    let height = 0;
    for (let element of elements) {
      const style = window.getComputedStyle(element);
      height += element.getBoundingClientRect().height + parseFloat(style.marginTop) + parseFloat(style.marginBottom);
    }
    return height;
  }

  function generatePagesFromSections(sections) {
    return sections.map((section, index) => (
      <PagePdf
        logos={config.logos}
        key={getRandomString(20)}
        currentPage={index ? index + 1 : undefined}
        totalPages={index ? sections.length : undefined}
        innerHTML={section}></PagePdf>
    ));
  }

  function splitMainPageBreaks() {
    if (!pdfGeneratorContainerRef.current) return;

    const sections = pdfGeneratorContainerRef.current.innerHTML.split('<div page-break="true"></div>');
    const onePagePdfContent = generatePagesFromSections(sections);
    setFinalPdfContent(onePagePdfContent);

    setTimeout(() => {
      splitFinalPdfPages();
    }, 300);
  }

  function splitTable(tableElement, allowedHeight) {
    // split table to fit page height
    // calc : table height - table content height + paginator height
    let tableContainerHeight =
      getHTMLElementsHeight(tableElement) - getHTMLElementsHeight(tableElement.children[1]) + 26;
    const separationIndexes = [];
    let index = 0;
    let cumulatedChildrenHeight = tableContainerHeight;

    for (let child of tableElement.children[1].children) {
      if (cumulatedChildrenHeight + getHTMLElementsHeight(child) < allowedHeight) {
        cumulatedChildrenHeight += getHTMLElementsHeight(child);
        index++;
      } else {
        separationIndexes.push(index);
        cumulatedChildrenHeight = tableContainerHeight + getHTMLElementsHeight(child);
        allowedHeight = config.pageHeight;
        index++;
      }
    }
    separationIndexes.push(index);

    //create sub tables
    const subTables = [];
    let splitRows = Array.from(tableElement.children[1].children).map(row => row.outerHTML);

    for (let i = 0; i < separationIndexes.length; i++) {
      splitRows = splitRows.slice(separationIndexes[i]);
    }
    splitRows = Array.from(tableElement.children[1].children).map(row => row.outerHTML);
    // return false if table can't be split
    if (!separationIndexes[0]) return false;

    for (let i = 0; i < separationIndexes.length; i++) {
      const newTable = tableElement.cloneNode(true);
      newTable.children[1].innerHTML = splitRows.slice(0, separationIndexes[i]).join("");

      splitRows = splitRows.slice(separationIndexes[i]);
      subTables.push(
        newTable.outerHTML + `<div class="table-paginator roboto-italic">${i + 1}/${separationIndexes.length}</div>`,
      );
    }

    return {
      firstTable: subTables[0],
      lastTable: subTables[subTables.length - 1],
      subTables: subTables.slice(1, subTables.length - 1),
      lastTableHeight: cumulatedChildrenHeight + 26,
    };
  }

  function splitParagraph(paragraphElement, allowedHeight) {
    if (!paragraphHeightCalcRef.current) return false;
    const originalContent = paragraphElement.innerHTML;
    paragraphElement.innerHTML = "";
    paragraphHeightCalcRef.current.innerHTML = paragraphElement.outerHTML;
    const textContainer = paragraphHeightCalcRef.current.children[0];
    let content = "";
    const splittedParagraphs = [];

    for (let word of originalContent.split(" ")) {
      content += word + " ";
      textContainer.innerHTML = content;

      if (getHTMLElementsHeight(textContainer) > allowedHeight) {
        content = content.slice(0, content.length - word.length - 1);
        splittedParagraphs.push(content);
        content = word + " ";
        allowedHeight = config.pageHeight;
      }
    }
    splittedParagraphs.push(content);
    textContainer.innerHTML = splittedParagraphs[0];

    for (let i = 0; i < splittedParagraphs.length; i++) {
      paragraphElement.innerHTML = splittedParagraphs[i];
      splittedParagraphs[i] = paragraphElement.outerHTML;
    }

    return {
      firstParagraph: splittedParagraphs.shift(),
      lastParagraph: splittedParagraphs.pop(),
      paragraphs: splittedParagraphs,
      lastParagraphHeight: getHTMLElementsHeight(paragraphHeightCalcRef.current),
    };
  }

  function groupSectionsInPages(htmlSections, JSXSections) {
    const newPages = [];
    let currentPage = [];
    let cumulatedChildrenHeight = 0;

    for (let i = 0; i < JSXSections.length; i++) {
      let sectionHeight = getHTMLElementsHeight(...JSXSections[i]);
      if (sectionHeight + cumulatedChildrenHeight < config.pageHeight) {
        currentPage.push(htmlSections[i]);
        cumulatedChildrenHeight += sectionHeight;
      } else {
        switch (JSXSections[i][0].localName) {
          //handle tables spliting
          case "table":
            const splittedTable = splitTable(JSXSections[i][0], config.pageHeight - cumulatedChildrenHeight);

            if (!splittedTable) {
              newPages.push(currentPage);
              currentPage = [htmlSections[i]];
              cumulatedChildrenHeight = sectionHeight;
            } else {
              currentPage.push(splittedTable.firstTable);
              newPages.push(currentPage, ...[splittedTable.subTables]);
              currentPage = [splittedTable.lastTable];
              cumulatedChildrenHeight = splittedTable.lastTableHeight;
            }
            break;
          //handle paragraphs spliting
          case "p":
            if (JSXSections[i][0].hasAttribute("can-page-break")) {
              const splittedParagraph = splitParagraph(JSXSections[i][0], config.pageHeight - cumulatedChildrenHeight);
              currentPage.push(splittedParagraph.firstParagraph);

              newPages.push(currentPage, ...[splittedParagraph.paragraphs]);
              currentPage = [splittedParagraph.lastParagraph];
              cumulatedChildrenHeight = splittedParagraph.lastParagraphHeight;
            } else {
              newPages.push(currentPage);
              currentPage = [htmlSections[i]];
              cumulatedChildrenHeight = sectionHeight;
            }
            break;
          default:
            newPages.push(currentPage);
            currentPage = [htmlSections[i]];
            cumulatedChildrenHeight = sectionHeight;
        }
      }
      sectionHeight = 0;
    }

    if (currentPage.length) newPages.push(currentPage);

    return newPages;
  }

  function splitFinalPdfPages() {
    if (!finalPdfContainerRef.current) return;
    const result = [];

    for (let pageIndex = 0; pageIndex < finalPdfContainerRef.current.children.length; pageIndex++) {
      const pageContent = finalPdfContainerRef.current.children[pageIndex].children[1];
      let childrenHeight = getHTMLElementsHeight(...pageContent.children);

      //check if page content height exceeds page height
      if (childrenHeight < config.pageHeight) {
        result.push([pageContent.innerHTML]);
        continue;
      }

      //split page content into multiple possible page breaks (HTML then JSX)
      const sortedJSXSections = [[]];
      const htmlSections = pageContent.innerHTML
        .split('<div possible-page-break="true"></div>')
        .filter(section => section.length);

      for (let i = 0; i < pageContent.children.length; i++) {
        if (pageContent.children[i].getAttribute("possible-page-break") === "true") {
          if (sortedJSXSections[sortedJSXSections.length - 1].length) sortedJSXSections.push([]);
        } else {
          sortedJSXSections[sortedJSXSections.length - 1].push(pageContent.children[i]);
        }
      }
      if (sortedJSXSections[sortedJSXSections.length - 1].length === 0) sortedJSXSections.pop();

      //rebuild new pages with sections
      const newPages = groupSectionsInPages(htmlSections, sortedJSXSections);
      result.push(...newPages.map(section => [section.join("")]));
    }

    setFinalPdfSections(result.filter(section => section[0].length));
  }

  function generateSummaryFromHtml(sections) {
    let summary = [];

    // Vérifier sur combien de pages le sommaire sera affiché
    let rowsCount = 0;
    let pagesCount = 1;
    const maxRowsBySummaryPage = config.maxRowsBySummaryPage || 20; // Par défaut 20 si non défini

    for (let pageIndex = 0; pageIndex < sections.length; pageIndex++) {
      const parser = new DOMParser();
      const sectionHtml = parser.parseFromString(sections[pageIndex][0], "text/html");
      for (const element of sectionHtml.body.children) {
        if (
          (element.hasAttribute("h2-title") || element.hasAttribute("h3-title")) &&
          !element.hasAttribute("not-on-summary")
        ) {
          if (rowsCount >= maxRowsBySummaryPage) {
            pagesCount++;
            rowsCount = 0;
          }
          rowsCount++;
        }
      }
    }

    for (let pageIndex = 0; pageIndex < sections.length; pageIndex++) {
      const parser = new DOMParser();
      const sectionHtml = parser.parseFromString(sections[pageIndex][0], "text/html");

      for (const element of sectionHtml.body.children) {
        if (element.hasAttribute("h2-title") && !element.hasAttribute("not-on-summary")) {
          summary.push({
            title: element.getAttribute("h2-title"),
            page: pageIndex + pagesCount + 1,
            subItems: [],
          });
        }
        if (element.hasAttribute("h3-title") && !element.hasAttribute("not-on-summary")) {
          if (summary.length > 0) {
            summary[summary.length - 1].subItems.push({
              subtitle: element.getAttribute("h3-title"),
              page: pageIndex + pagesCount + 1,
            });
          }
        }
      }
    }
    setSummaryChapters(summary);
  }

  function getEnseigneName(mainYear) {
    return mainYear?.infos?.enseigne;
  }

  // last step of PDF generation
  function generatePdfWithSummary(sections) {
    if (!summaryContainerRef.current) return;
    const htmlSections = summaryContainerRef.current.innerHTML
      .split('<div possible-page-break="true"></div>')
      .filter(section => section.length);

    const JSXSections = [[]];

    for (let i = 0; i < summaryContainerRef.current.children.length; i++) {
      if (summaryContainerRef.current.children[i].getAttribute("possible-page-break") === "true") {
        if (JSXSections[JSXSections.length - 1].length) JSXSections.push([]);
      } else {
        JSXSections[JSXSections.length - 1].push(summaryContainerRef.current.children[i]);
      }
    }
    if (JSXSections[JSXSections.length - 1].length === 0) JSXSections.pop();

    const summaryPages = groupSectionsInPages(htmlSections, JSXSections);
    setFinalPdfContent([
      ...generatePagesFromSections([
        sections[0],
        sections[1],
        ...summaryPages.map(section => [section.join("")]),
        ...sections.slice(2),
      ]),
    ]);

    setTimeout(async () => {
      await replaceImagesWithBase64();
      setIsButtonDisabled(false);
    }, 500);
  }

  function addAnchorsOnTitles(element) {
    if (element.hasAttribute("h2-title") && !element.hasAttribute("not-on-summary")) {
      element.setAttribute("id", generateHrefPdf(element.getAttribute("h2-title")));
    }
    if (element.hasAttribute("h3-title") && !element.hasAttribute("not-on-summary")) {
      element.setAttribute("id", generateHrefPdf(element.getAttribute("h3-title")));
    }

    for (let child of element.children) {
      addAnchorsOnTitles(child);
    }
  }

  // CONVERTING PREVIEW INTO PDF
  async function generatePdf() {
    setIsPdfNotificationLoading(true);
    try {
      const globalCssVariables = getRootAndFontFaces();
      const pdfElement = finalPdfContainerRef.current;

      const styles = new Set();
      const elements = pdfElement.querySelectorAll("*");

      for (const element of elements) {
        const cssText = getCssTextForElement(element);
        if (cssText) {
          styles.add(cssText);
        }
      }

      const minifiedStyles = minifyCss([...styles].join(" "));
      const configStyle = getCSSFromConfig(config);
      const content = `
        <meta charset="UTF-8">
        <style>${globalCssVariables}${minifiedStyles}${configStyle}</style>
        <div class="final-pdf pdf-container">${pdfElement.innerHTML}</div>
      `;

      const fileName = `${
        getEnseigneName(mainYear) ? getEnseigneName(mainYear).toLowerCase().replace(/ /g, "_") : "enseigne"
      }_rapport_estimation.pdf`;

      const userId = getUuid();
      const estimationId = estimationIdParam;

      const response = await generatePdfApi(userId, estimationId, content, fileName);

      const pdfPath = response.data.pdf_path;

      const pdfBlob = await fetch(pdfPath).then(res => res.blob());

      saveAs(pdfBlob, fileName);
      setIsPdfNotificationLoading(false);
    } catch (error) {
      createNotification(<>Une erreur est survenue lors de la génération du PDF</>, "var(--red)", "var(--dark-blue)");
    } finally {
      setIsPdfNotificationLoading(false);
    }
  }

  function getCSSFromConfig(config) {
    let cssString = "* {";
    for (const [key, value] of Object.entries(config.style)) {
      if (key.startsWith("--")) {
        cssString += `${key}: ${value}; `;
      }
    }

    cssString += ` font-family: ${config.style.fontFamily}; }`;

    return cssString;
  }

  function getRootAndFontFaces() {
    return `
      :root {
        --black: #000000;
        --white: #ffffff;
        --dark-blue: #252b41;
        --darker-blue: #180047;
        --blue: #000471;
        --link-blue: #2727ff;
        --sky-blue: #6064d6;
        --pale-blue: #d3e1f7;
        --dark-blue-alt: #6d6f92;
        --grey: #939393;
        --light-blue: #e7eaf5;
        --light-grey: #d9d9d9;
        --beige: #f1e9d0;
        --creamy-white: #fcf9f5;
        --dark-gold: #8c742e;
        --gold: #ccb46f;
        --light-gold: #ebd595;
        --red: #f56e52;
        --green: #199016;
        --light-green: #21be1d;
        --dark-red: #ef3749;
        --orange: #ce3200;
      }
      .page-pdf-container {
        border: none !important;
      }
      .pdf-container .h2-pdf-number {
        -webkit-text-stroke-width: 4px !important;
      }
      .pdf-container .descriptive-text-content:before, .pdf-container .strong-point:before, .pdf-container .weak-point:before, .pdf-container .dots-list li::before {
        content: "";
        mask-image: url("data:image/svg+xml;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFmSURBVHgBbVI9TwJBEJ2ZpTIW1sIRDg21pZBY8FGZEDttMbHXxB8gxt7oL8CaykQbE04Kiyu1lRCOcHc9lQVyO84eHjnQaXZm9s3M23mLkDL7LVviOZQxoryJSeFER5HrNcJBgsHEKTpWi5HLcRLxy5zMvGFOBeQMa5PusqDQs06QuCaQT9DwnHTc7WdzUUSnQJxjRndc9x/I0IjBGgOvFt4iqkO7b3l2P/euNZ55Df9GRg5Qptu9bCmDM6oIFVBKd4pOvi3+FfBiOCPsFZz8FDLRE3zjJSsokyQtw3lYDQPxj2DNpPO5dxAODAY1WbR6zVvrBQw8TcdEqCdmG4Yfsr6HPyPgcedl2zIYJu2TzoD7e9Uc1cM7RmxL1w+Jx/Kei3E1uNZKteLaCFxc0UA2pTZ1Z7gfBomQMKemCFISco5ssbsUzn7NHkuP+n/CoWgwEg0WDFMWf40ZVCSZiwsIfEMj/TV+AGwcqD2nXAE5AAAAAElFTkSuQmCC");
        -webkit-mask-image: var(--dot);
        position: absolute;
        height: 14px;
        width: 14px;
        background-color: var(--secondary-color);
        background-repeat: no-repeat;
        left: -21px;
        top: 4px;
      }
      .pdf-container .strong-point:before {
        background-color: var(--light-green);
      }
      .pdf-container .weak-point:before {
        background-color: var(--red);
      }
      @font-face {
        font-family: "Outfit";
        font-weight: 900;
        font-style: normal;
        src:
          local("Outfit-Black"),
          url("https://beta.api.estimermoncommerce.fr/assets/Pdf/media/Outfit-Black.ttf") format("truetype");
      }
      @font-face {
        font-family: "Outfit";
        font-weight: 700;
        font-style: normal;
        src:
          local("Montserrat-Bold"),
          url("https://beta.api.estimermoncommerce.fr/assets/Pdf/media/Outfit-Bold.ttf") format("truetype");
      }
      @font-face {
        font-family: "Outfit";
        font-weight: 600;
        font-style: normal;
        src:
          local("Outfit-SemiBold"),
          url("https://beta.api.estimermoncommerce.fr/assets/Pdf/media/Outfit-SemiBold.ttf") format("truetype");
      }
      @font-face {
        font-family: "Outfit";
        font-weight: 500;
        font-style: normal;
        src:
          local("Outfit-Medium"),
          url("https://beta.api.estimermoncommerce.fr/assets/Pdf/media/Outfit-Medium.ttf") format("truetype");
      }
      @font-face {
        font-family: "Outfit";
        font-weight: 300;
        font-style: normal;
        src:
          local("Outfit-Medium"),
          url("https://beta.api.estimermoncommerce.fr/assets/Pdf/media/Outfit-Light.ttf") format("truetype");
      }
      @font-face {
        font-family: "Montserrat-Bold";
        src:
          local("Montserrat-Bold"),
          url("https://beta.api.estimermoncommerce.fr/assets/Pdf/media/Montserrat-Bold.ttf") format("truetype");
      }
      @font-face {
        font-family: "Outfit";
        font-weight: 200;
        font-style: normal;
        src:
          local("Outfit-Medium"),
          url("https://beta.api.estimermoncommerce.fr/assets/Pdf/media/Outfit-ExtraLight.ttf") format("truetype");
      }
    `;
  }

  function getCssTextForElement(element) {
    const sheets = Array.from(document.styleSheets).filter(sheet => {
      try {
        return Array.from(sheet.cssRules).some(rule => element.matches(rule.selectorText));
      } catch (e) {
        return false;
      }
    });

    let cssText = "";

    for (const sheet of sheets) {
      try {
        for (const rule of sheet.cssRules) {
          if (element.matches(rule.selectorText)) {
            cssText += rule.cssText;
          }
        }
      } catch (e) {
        createNotification(
          <>Une erreur est survenue lors de la génération du PDF. Veuillez réessayer</>,
          "var(--red)",
          "var(--dark-blue)",
        );
      }
    }

    return cssText;
  }

  function minifyCss(css) {
    return minify(css).css;
  }

  // MISC

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  function sortYears(years) {
    return years.sort((a, b) => {
      const [dayA, monthA, yearA] = a?.estimation?.infos?.date_bilan.split("/");
      const [dayB, monthB, yearB] = b?.estimation?.infos?.date_bilan.split("/");
      a.estimation.year = yearA;
      b.estimation.year = yearB;
      return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
    });
  }

  const numberForH2Pdf = estimationType === 3 ? 5 : 8;

  const AdditionalInformation = (
    <>
      <>
        <H2Pdf importanceLevel={2} title='Informations complémentaires' number={numberForH2Pdf} />
        <H3Pdf title='Précautions quant à l’estimation réalisée dans ce rapport' />
        <div className='pdf-text-container'>
          <p>
            Un certain nombre de facteurs peuvent impacter la concordance entre le résultant de l'estimation et le futur
            prix effectif de cession du fonds de commerce :
          </p>
          <h4>Eléments inhérents au bien</h4>
          <p>Tels que :</p>
          <ul className='dots-list'>
            <li>L’attrait que représente celle-ci pour l’acquéreur potentiels</li>
            <li>L'impératif de cession</li>
            <li>
              Plus globalement d'éventuels atouts ou défauts fortement disruptifs de l'affaire ou du bien par rapport à
              des normes classiques de marché
            </li>
          </ul>
          <h4>Eléments externes au bien</h4>
          <p>Tels que :</p>
          <ul className='dots-list'>
            <li>Les fluctuations des paramètres du marché</li>
            <li>Les prix de cession effectifs</li>
            <li>
              Plus globalement d'éventuels paramètres géographiques, conjoncturels, sociaux ou métier, qui seraient
              fortement disruptifs par rapport à des normes classiques de marché
            </li>
          </ul>
          <p>
            La fixation du prix que le vendeur pense être en mesure d'obtenir dans le cadre d'une cession relève de son
            entière décision. Pour cela il est conseillé au vendeur, outre se baser sur le résultat d'estimation fourni
            par ce rapport, de se rapprocher de ses conseils habituels, ainsi que de faire appel à sa propre perception
            du marché et de la valeur de son bien.
          </p>
          <p className='pdf-blue-background'>
            Rappelez vous toujours une règle d’or en affaire : au-delà de toutes les estimations possibles, le prix
            final est celui où se rejoignent acquéreur et cédant.
          </p>
          <p>Le prix effectif de vente du fonds de commerce ne pourra en aucun cas nous être opposé.</p>
          <div page-break='true' />
        </div>
      </>
      <div className='pdf-text-container'>
        <h4>A quoi ce rapport peut désormais vous servir ?</h4>
        <ul className='dots-list'>
          <li>
            Avoir une base de valeur pour engager des négociations dans l'optique de vendre l'affaire à court terme;
          </li>
          <li>Optimiser l'exploitation en améliorant dans la mesure du possible ses faiblesses mises en lumière;</li>
          <li>
            Prévoir des stratégies locatives, commerciales, comptables ou fiscales à mettre en place pour optimiser la
            valeur des murs commerciaux et envisager leur vente à moyen ou long terme;
          </li>
          <li>Approcher des partenaires bancaires pour solliciter des financements de soutien et de développement;</li>
        </ul>
        <h4>La valeur indiquée dans ce rapport diffère de vos attentes ?</h4>
        <ul className='dots-list'>
          <li className='pdf-bold'>Vous vous attendiez à une valeur inférieure ?</li>
        </ul>
        <p>
          Personne ne connaît mieux l'affaire que l'exploitant lui-même. Bien que nous ayons tenu compte d'un grand
          nombre de facteurs pour réaliser cette évaluation, il est probable que des facteurs additionnels que nous ne
          connaissons pas (par exemple la forte personnalisation de l'affaire, ou des habitudes de consommation propres
          à l'implantation) viennent affecter la valeur de l'affaire à la baisse.
        </p>
        <ul className='dots-list'>
          <li className='pdf-bold'>Vous vous attendiez à une valeur supérieure ?</li>
        </ul>
        <p>
          En tant qu'exploitant, maître à bord de l'affaire, l'on y a consacré temps et énergie considérables. De ce
          fait, il est normal que l'on y affecte une valeur difficile à calculer, que l'on pourrait nommer valeur du
          travail ou valeur sentimentale. Cette valeur ne peut cependant pas être prise en compte dans l'évaluation du
          fonds de commerce.
        </p>
        <p>
          Si d'autres affaires ont pu être cédées à un prix « hors marché » relevant de ratios significativement
          supérieurs, rappelez-vous que nous avons pour objectif de livrer une évaluation réaliste, permettant, dans un
          projet de cession de l'affaire, d'obtenir les meilleures probabilités de trouver un repreneur dans des
          conditions de faisabilité convergentes pour les deux parties.
        </p>
        <p>
          Cependant, il est également possible que malgré toutes nos précautions et l'anticipation du plus grand nombre
          de cas de figure existants, des facteurs additionnels intrinsèques à l'affaire, que nous ne pouvons pas
          identifier, viennent affecter la valeur de l'affaire à la hausse.
        </p>
      </div>
    </>
  );

  return (
    <PdfContext.Provider
      value={{
        mainYear,
        rapportData,
        visitingCard,
        config,
        estimationType,
        formatDataForStrongWeakPoints,
        formatDataForDescriptiveText,
        getEnseigneName,
        estimationData,
        businessImages,
        AdditionalInformation,
      }}>
      <PdfTdsFdcProvider>
        <div style={{ ...config.style }}>
          <div className='pdf-download-container'>
            <FilledButton
              // bgColor={isButtonDisabled ? "grey" : "var(--secondary-color)"}
              // hoverColor={isButtonDisabled ? "grey" : "var(--secondary-color)"}
              onClick={generatePdf}
              isLoading={isPdfNotificationLoading}
              disabled={isButtonDisabled}>
              {isButtonDisabled ? (
                <>
                  <Download width='30px' />
                  En cours de préparation...
                </>
              ) : (
                <>
                  <Download width='30px' />
                  Télécharger le PDF
                </>
              )}
            </FilledButton>
          </div>
          {/* pdf generator */}
          <div className='pdf-container generator-container pdf-generator-container' ref={pdfGeneratorContainerRef}>
            {estimationType === 3 ? (
              <PdfMc />
            ) : estimationType === 1 ? (
              <PdfFdc />
            ) : estimationType === 2 ? (
              <PdfTds />
            ) : null}
          </div>
          {/* paragraphs height calculator */}
          <div className='generator-container pdf-container'>
            <div ref={paragraphHeightCalcRef} className='page-pdf' />
          </div>
          {/* Summary generator */}
          <div className='generator-container'>
            <PagePdf>
              <div ref={summaryContainerRef}>
                <H2Pdf title='Sommaire' />
                <div className='default-margin-placeholder' />
                <SummaryPdf summaryChapters={summaryChapters} />
              </div>
            </PagePdf>
          </div>
          {/* final pdf */}
          <div className='final-pdf-container'>
            <div className='final-pdf pdf-container' style={{ ...config.style }} ref={finalPdfContainerRef}>
              {finalPdfContent.map((page, index) => (
                <Fragment key={index}>{page}</Fragment>
              ))}
            </div>
            <div className='top-scroller-container'>
              <div className='top-scroller' onClick={scrollToTop} />
            </div>
          </div>
        </div>
      </PdfTdsFdcProvider>
    </PdfContext.Provider>
  );
}
export default Pdf;
