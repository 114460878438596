import { useNavigate } from "react-router-dom";
import "./AccountFinancingPlans.css";
import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "../../../../../context/AuthContext";
import { useWindowSize } from "@uidotdev/usehooks";
import { archiveFinancingPlanApi, fetchAllFinancingPlanApi } from "../../../../../api/FinancingPlanApi";
import AppContext from "../../../../../context/AppContext";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../components/atoms/icons/general/add/Add";
import Searchbar from "../../../../../components/molecules/searchbar/Searchbar";
import Pen from "../../../../../components/atoms/icons/account/pen/Pen";
import Duplicate from "../../../../../components/atoms/icons/account/duplicate/Duplicate";
import Unarchive from "../../../../../components/atoms/icons/account/unarchive/Unarchive";
import Archive from "../../../../../components/atoms/icons/account/archive/Archive";
import DuplicateFinancingPlanModal from "../../../../../components/forms/modals/account/duplicateFinancingPlanModal/DuplicateFinancingPlanModal";
import { normalizeString } from "../../../../../utils/Utils";

function AccountFinancingPlans() {
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [financingPlans, setFinancingPlans] = useState([]);
  const [visibleFinancingPlans, setVisibleFinancingPlans] = useState([]);
  const [duplicateFinancingPlanInput, setDuplicateFinancingPlanInput] = useState({
    value: "",
    error: "",
  });
  const [resetSearchValueTrigger, setResetSearchValueTrigger] = useState(false);
  const { createNotification, setModalContent, setModalVisible } = useContext(AppContext);
  const { getUuid } = useAuth();
  const navigate = useNavigate();

  const { width } = useWindowSize();

  useEffect(() => {
    fetchAllFinancingPlans();
  }, []);

  useEffect(() => {
    setResetSearchValueTrigger(!resetSearchValueTrigger);
  }, [activeTab]);

  useEffect(() => {
    if (financingPlans.length) setVisibleFinancingPlans(getFilteredFinancingPlans(financingPlans));
  }, [activeTab, searchValue]);

  async function fetchAllFinancingPlans() {
    try {
      const res = (await fetchAllFinancingPlanApi(getUuid())).data;

      const financingPlans = [];
      financingPlans.push(...[...res.financing_plan_fdc, ...res.financing_plan_murs]);

      setFinancingPlans(financingPlans);
      setVisibleFinancingPlans(getFilteredFinancingPlans(financingPlans));
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors de la récupération de vos plans de financement, veuillez réessayer.
          <br />
          Code d'erreur {error.reponse.status}
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      //setAppLoaderVisible(false);
    }
  }

  function getFilteredFinancingPlans(financingPlans) {
    if (searchValue === "") return financingPlans.filter(plan => plan.archived === !!activeTab);

    return financingPlans.filter(
      plan =>
        plan.archived === !!activeTab &&
        (normalizeString(plan.financing_plan_name).includes(normalizeString(searchValue)) ||
          normalizeString(getFinancingPlanType(plan)).includes(normalizeString(searchValue))),
    );
  }

  function showDuplicateFinancingPlanModal(planId, planName) {
    setModalContent({
      title: "Dupliquer le plan de financement",
      content: (
        <DuplicateFinancingPlanModal
          defaultValue={getDuplicateFinancingPlanName(planName)}
          fetchFinancingPlansFunction={() => fetchAllFinancingPlans()}
          financingPlanId={planId}
        />
      ),
    });
    setModalVisible(true);
  }

  async function archiveFinancingPlan(planId, shouldArchive) {
    try {
      await archiveFinancingPlanApi(planId, shouldArchive);
      await fetchAllFinancingPlans();
      createNotification(<>Votre plan de financement a été {shouldArchive ? "archivé" : "désarchivé"} avec succès</>);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors {shouldArchive ? "de l'archivage" : "du désarchivage"} de votre plan de
          financement. Veuillez réessayer
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function getDuplicateFinancingPlanName(planName) {
    const regex = /#\d+$/;

    if (regex.test(planName)) {
      const match = planName.match(/\d+$/);

      return planName.replace(regex, `#${parseInt(match[0]) + 1}`);
    } else {
      return `${planName} #1`;
    }
  }

  function getFinancingPlanUrl(plan) {
    return `/plan-financement/${plan.financing_plan_type}/${plan.financing_plan_id}`;
  }

  function getFinancingPlanType(plan) {
    return plan.financing_plan_type === "fdc" ? "Fonds" : "Murs";
  }

  return (
    <>
      <section className='container'>
        <FilledButton className='mid-page-button plans-button' to='/plan-financement/fdc'>
          <Add width={width > 576 ? "30px" : "20px"} /> Créer un plan de financement
        </FilledButton>
        <div className='plans-container'>
          <div className='plans-container-header'>
            <p className={activeTab === 0 ? "active" : ""} onClick={() => setActiveTab(0)}>
              Mes Plans de financement
            </p>
            <p className={activeTab === 1 ? "active" : ""} onClick={() => setActiveTab(1)}>
              Mes Plans de financement archivés
            </p>
          </div>
          <div className='search-input-container'>
            <Searchbar
              resetValueTrigger={resetSearchValueTrigger}
              onChange={value => setSearchValue(value)}
              placeholder='Rechercher'
              bgColor={width > 1200 ? "var(--white)" : "var(--beige)"}
              className='plans-search-bar'
            />
          </div>
          <div className='plans-table'>
            <div className='plans-filters'>
              <div>Nom du plan de financement</div>
              <div>Date de création</div>
              <div>Type</div>
              <div className='plans-filters-buttons'>
                <div className='centered' custom-title='Modifier'>
                  <Pen width='23px' />
                </div>
                <div className='centered' custom-title='Dupliquer'>
                  <Duplicate width='25px' />
                </div>
                <div className='centered' custom-title={activeTab ? "Désarchiver" : "Archiver"}>
                  {activeTab ? <Unarchive width='25px' /> : <Archive width='25px' />}
                </div>
              </div>
            </div>
            <div className='plans-table-body'>
              {visibleFinancingPlans.map(plan => (
                <div key={plan.financing_plan_id} className='plan-overview-container'>
                  <div>{plan.financing_plan_name}</div>
                  <div>{plan.date_creation.split(" ")[0]}</div>
                  <div>{getFinancingPlanType(plan)}</div>
                  <div className='plans-filters-buttons'>
                    <FilledButton bgColor='#E6CC83' hoverColor='#E6CC83' to={getFinancingPlanUrl(plan)}>
                      Accéder
                    </FilledButton>
                    <FilledButton
                      bgColor='#E3E3E3'
                      hoverColor='#E3E3E3'
                      onClick={() =>
                        showDuplicateFinancingPlanModal(plan.financing_plan_id, plan?.financing_plan_name)
                      }>
                      Dupliquer
                    </FilledButton>
                    {plan.archived ? (
                      <FilledButton
                        bgColor='#FDB7A9'
                        hoverColor='#FDB7A9'
                        onClick={() => archiveFinancingPlan(plan.financing_plan_id, false)}>
                        Désarchiver
                      </FilledButton>
                    ) : (
                      <FilledButton
                        bgColor='#FDB7A9'
                        hoverColor='#FDB7A9'
                        onClick={() => archiveFinancingPlan(plan.financing_plan_id, true)}>
                        Archiver
                      </FilledButton>
                    )}
                  </div>
                </div>
              ))}
              {!visibleFinancingPlans.length && (
                <p className='text-lg outfit-semibold my-md text-center'>
                  Aucun résultat ne correspond à votre recherche
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AccountFinancingPlans;
