import React, { useContext, useState, useEffect } from "react";
import "./EditHeaderFooterReportLogoForm.css";
import { useForm } from "react-hook-form";
import AppContext from "../../../../context/AppContext";
import FileInput from "../../../molecules/formComponents/fileInput/FileInput";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../../molecules/buttons/unfilledButton/UnfilledButton";
import defaultHeaderLogo from "../../../../assets/reportLogo/logo_emc_blanc_carre.svg";
import defaultFooterLogo from "../../../../assets/reportLogo/logo_emc_fonds_sombre.svg";
import Add from "../../../atoms/icons/general/add/Add";

function EditHeaderFooterReportLogoForm({ type = "header" }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const defaultLogo = type === "header" ? defaultHeaderLogo : defaultFooterLogo;
  const { setModalVisible, createNotification } = useContext(AppContext);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(defaultLogo);

  /*

  const data_header = {
    "header_banner_logo": [
      "name": 'logo_name',
      "data": "base64"
    ]
  };

    const data_footer = {
    "footer_banner_logo": [
      "name": "logo_name",
      "data": "base64"
    ]
  };
  
  */

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  async function onSubmit(data) {
    //TODO envoi au back
  }

  function handleFileChange(compressedFile) {
    if (compressedFile) {
      setSelectedLogo(compressedFile);
      setPreviewUrl(compressedFile.data);
    }
  }

  function handleFileClear() {
    setSelectedLogo(null);
    setPreviewUrl(defaultLogo);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='edit-report-logo-form'>
      <div className='preview-container-logo'>
        {type === "header" ? (
          <div className='header-banner-color-logo'>
            {previewUrl && <img src={previewUrl} alt='Aperçu du logo' className='header-logo-preview' />}
          </div>
        ) : (
          <div className='footer-banner-color-logo'>
            {previewUrl && <img src={previewUrl} alt='Aperçu du logo' className='footer-logo-preview' />}
            <p>Page X/XX</p>
          </div>
        )}
      </div>
      <div className='edit-report-container'>
        <div className='logo-preview-container'>
          <div>
            <FileInput
              name='reportLogo'
              icon={<Add width='25px' color='var(--white)' />}
              buttonText='Importer un logo'
              removeButtonText='Retirer le logo'
              onChange={handleFileChange}
              onClear={handleFileClear}
              accept='image/*'
              required
            />
          </div>
        </div>
      </div>
      {errors.reportLogo && <p className='error-message'>Ce champ est requis</p>}
      <div className='modal-buttons-row mt-md'>
        <UnfilledButton onClick={() => setModalVisible(false)}>Annuler</UnfilledButton>
        <FilledButton type='submit'>Enregistrer</FilledButton>
      </div>
    </form>
  );
}

export default EditHeaderFooterReportLogoForm;
