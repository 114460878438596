import { generateHrefPdf } from "../summaryPdf/SummaryPdf";
import "./AnnexPdf.css";
import React from "react";

function AnnexPdf({ subtitles = [] }) {
  return (
    <>
      <div className='annex-pdf-id' />
      <h2 h2-title='Annexes' importance-level={1} className='annex-title'>
        Annexes
      </h2>
      <div className='annex-pdf-subtitles'>
        {subtitles.map((subtitle, index) => (
          <a href={"#" + generateHrefPdf(subtitle)} key={index}>
            {subtitle}
          </a>
        ))}
      </div>
    </>
  );
}

export default AnnexPdf;
