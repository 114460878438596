import React, { useContext, useEffect } from "react";
import Input from "../../../../molecules/formComponents/input/Input";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import AppContext from "../../../../../context/AppContext";
import { defaultValidation } from "../../../../../utils/formValidation/FormValidation";
import { useForm } from "react-hook-form";
import { duplicateCreditSimulatorApi } from "../../../../../api/CreditSimulatorApi";

function DuplicateCreditSimulatorModal({
  creditSimulatorId,
  defaultValue,
  fetchFinancingPlansFunction = async () => {},
}) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { setModalVisible, createNotification } = useContext(AppContext);

  useEffect(() => {
    setValue("name", defaultValue);
  }, [defaultValue]);

  async function duplicateFinancingPlan(values) {
    try {
      await duplicateCreditSimulatorApi(creditSimulatorId, values.name);
      await fetchFinancingPlansFunction();
      createNotification(<>Le tableau d'amortissement {values.name} a été dupliqué avec succès.</>);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors de la duplication de votre tableau d'amortissement. Veuillez réessayer plus tard
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
    setModalVisible(false);
  }
  return (
    <form onSubmit={handleSubmit(duplicateFinancingPlan)}>
      <Input
        validation={defaultValidation}
        useForm={{ register, setValue }}
        error={errors?.enseigne?.message}
        name='name'
        label="Nom du tableau d'amortissement"
      />
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' type='submit'>
          Dupliquer le tableau d'amortissement
        </FilledButton>
      </div>
    </form>
  );
}

export default DuplicateCreditSimulatorModal;
