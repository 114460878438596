import axiosInstance from "./AxiosConfig";

//Folders :

export const createFolderApi = async (folderName, descriptionFolder, teamId, userId) =>
  axiosInstance.post("/folders/create", {
    folder_name: folderName,
    description: descriptionFolder,
    team_id: teamId,
    user_id: userId,
  });

export const fetchUserFoldersApi = async uuid =>
  axiosInstance.get("/folders", {
    params: {
      method: "user",
      id: uuid,
    },
  });

export const fetchFolderByIdApi = async folderId =>
  axiosInstance.get("/folders", {
    params: {
      method: "folder",
      id: folderId,
    },
  });

export const duplicateFolderApi = async (folderId, folderName) =>
  axiosInstance.post("/folders/duplicate", {
    folder_id: folderId,
    folder_name: folderName,
  });

export const archiveFolderApi = async (folderId, shouldArchive) =>
  axiosInstance.post(`/folders/archive`, {
    folder_id: folderId,
    archive: shouldArchive,
  });

export const updateFolderApi = async (folderId, teamId, folderName, descriptionFolder) =>
  axiosInstance.put(`/folders/edit/${folderId}`, {
    team_id: teamId,
    folder_name: folderName,
    description: descriptionFolder,
  });

export const deleteFolderApi = async folderId => axiosInstance.delete(`/folders/delete/${folderId}`);

//Estimations :

export const addEstimationToFolderApi = async (folderId, estimationId) =>
  axiosInstance.post("/folders/add/estimation", {
    folder_id: folderId,
    estimation_id: estimationId,
  });

export const deleteEstimationFromFolderApi = async (folderId, estimationId, userConsent = false) =>
  axiosInstance.delete(`/folders/delete/${folderId}/estimation/${estimationId}`, {
    params: {
      "user-consent": userConsent,
    },
  });

export const deleteAllEstimationsFromFolderApi = async (folderId, userConsent = false) =>
  axiosInstance.delete(`/folders/delete/${folderId}/estimation/all`, {
    params: {
      "user-consent": userConsent,
    },
  });

export const addEstimationToDeliverableApi = async (deliverableId, estimationId) =>
  axiosInstance.post("/folders/deliverable/add/estimation", {
    deliverable_id: deliverableId,
    estimation_id: estimationId,
  });

export const deleteEstimationFromDeliverableApi = async (deliverableId, estimationId) =>
  axiosInstance.delete(`/folders/deliverable/delete/${deliverableId}/estimation/${estimationId}`);

export const deleteAllEstimationsFromDeliverableApi = async deliverableId =>
  axiosInstance.delete(`/folders/deliverable/delete/${deliverableId}/estimation/all`);

//Financing Plans :

export const addFinancingPlanToFolderApi = async (folderId, financingPlanId) =>
  axiosInstance.post("/folders/add/financing-plan", {
    folder_id: folderId,
    financing_plan_id: financingPlanId,
  });

export const deleteFinancingPlanFromFolderApi = async (folderId, financingPlanId, userConsent = false) =>
  axiosInstance.delete(`/folders/delete/${folderId}/financing-plan/${financingPlanId}`, {
    params: {
      "user-consent": userConsent,
    },
  });

export const deleteAllFinancingPlansFromFolderApi = async (folderId, userConsent = false) =>
  axiosInstance.delete(`/folders/delete/${folderId}/financing-plan/all`, {
    params: {
      "user-consent": userConsent,
    },
  });

export const addFinancingPlanToDeliverableApi = async (deliverableId, financingPlanId) =>
  axiosInstance.post("/folders/deliverable/add/financing-plan", {
    deliverable_id: deliverableId,
    financing_plan_id: financingPlanId,
  });

export const deleteFinancingPlanFromDeliverableApi = async (deliverableId, financingPlanId) =>
  axiosInstance.delete(`/folders/deliverable/delete/${deliverableId}/financing-plan/${financingPlanId}`);

export const deleteAllFinancingPlansFromDeliverableApi = async deliverableId =>
  axiosInstance.delete(`/folders/deliverable/delete/${deliverableId}/financing-plan/all`);

// Credit Simulators :

export const addCreditSimulatorToFolderApi = async (folderId, creditSimulatorId) =>
  axiosInstance.post("/folders/add/credit-simulator", {
    folder_id: folderId,
    credit_id: creditSimulatorId,
  });

export const deleteCreditSimulatorFromFolderApi = async (folderId, creditSimulatorId, userConsent = false) =>
  axiosInstance.delete(`/folders/delete/${folderId}/credit-simulator/${creditSimulatorId}`, {
    params: {
      "user-consent": userConsent,
    },
  });

export const deleteAllCreditSimulatorsFromFolderApi = async (folderId, userConsent = false) =>
  axiosInstance.delete(`/folders/delete/${folderId}/credit-simulator/all`, {
    params: {
      "user-consent": userConsent,
    },
  });

export const addCreditSimulatorToDeliverableApi = async (deliverableId, creditSimulatorId) =>
  axiosInstance.post("/folders/deliverable/add/credit-simulator", {
    deliverable_id: deliverableId,
    credit_id: creditSimulatorId,
  });

export const deleteCreditSimulatorFromDeliverableApi = async (deliverableId, creditSimulatorId) =>
  axiosInstance.delete(`/folders/deliverable/delete/${deliverableId}/credit-simulator/${creditSimulatorId}`);

export const deleteAllCreditSimulatorsFromDeliverableApi = async deliverableId =>
  axiosInstance.delete(`/folders/deliverable/delete/${deliverableId}/credit-simulator/all`);

//Deliverables :

export const createDeliverableApi = async (
  deliverableName,
  folderId,
  estimationIds,
  financingPlanIds,
  creditSimulatorIds,
) =>
  axiosInstance.post("/folders/deliverable/create", {
    deliverable_name: deliverableName,
    folder_id: folderId,
    estimations: estimationIds,
    financing_plans: financingPlanIds,
    credits: creditSimulatorIds,
  });

export const duplicateDeliverableApi = async (deliverableId, deliverableName) =>
  axiosInstance.post("/folders/deliverable/duplicate", {
    deliverable_id: deliverableId,
    deliverable_name: deliverableName,
  });

export const fetchDeliverableByIdApi = async deliverableId =>
  axiosInstance.get("/folders/deliverable", {
    params: {
      method: "deliverable",
      id: deliverableId,
    },
  });

export const fetchDeliverablesByFolderIdApi = async folderId =>
  axiosInstance.get("/folders/deliverable", {
    params: {
      method: "folder",
      id: folderId,
    },
  });

export const updateDeliverableApi = async (deliverableId, deliverableName) =>
  axiosInstance.put(`/folders/deliverable/edit/${deliverableId}`, {
    deliverable_name: deliverableName,
  });

export const deleteDeliverableApi = async deliverableId =>
  axiosInstance.delete(`/folders/deliverable/delete/${deliverableId}`);
