import React, { useState, useEffect } from "react";
import "./AccountTools.css";
import Chart from "../../../../../components/atoms/icons/header/chart/Chart";
import Rapport from "../../../../../components/atoms/icons/header/rapport/Rapport";
import Emc from "../../../../../components/atoms/icons/header/emc/Emc";
import ComingSoon from "../../../../../components/atoms/icons/general/comingSoon/ComingSoon";
import AccountEstimations from "../accountEstimations/AccountEstimations";
import AccountFinancingPlans from "../accountFinancingPlans/AccountFinancingPlans";
import AccountCreditSimulators from "../accountCreditSimulators/AccountCreditSimulators";
import Hr from "../../../../../components/molecules/hr/Hr";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

function AccountTools() {
  const tools = [
    { name: "Mes estimations", type: "accessible", urlSegment: "mes-estimations" },
    { name: "Mes plans de financement", type: "accessible", urlSegment: "mes-plans-de-financement" },
    { name: "Mes tableaux d'amortissement", type: "accessible", urlSegment: "mes-tableaux-d-amortissement" },
    // { name: "Étude d'implantation", type: "comingSoon", urlSegment: "etude-d-implantation" },
    // { name: "Annonces", type: "comingSoon", urlSegment: "annonces" },
  ];
  const iconMap = {
    "Mes estimations": Emc,
    "Mes plans de financement": Rapport,
    "Mes tableaux d'amortissement": Chart,
    // "Étude d'implantation": ComingSoon,
    // "Annonces": ComingSoon,
  };
  const [selectedTool, setSelectedTool] = useState(tools[0]);
  const navigate = useNavigate();
  const { toolParam } = useParams();

  useEffect(() => {
    setSelectedTool(tools.find(tool => tool.urlSegment === toolParam) || tools[0]);
  }, [toolParam]);

  function renderIcon(tool) {
    const IconComponent = iconMap[tool.name];
    return IconComponent ? <IconComponent width='20px' /> : null;
  }

  function renderToolContent(tool) {
    switch (tool.name) {
      case "Mes estimations":
        return <AccountEstimations />;
      case "Mes plans de financement":
        return <AccountFinancingPlans />;
      case "Mes tableaux d'amortissement":
        return <AccountCreditSimulators />;
      default:
        return navigate("/404");
    }
  }

  function navigateToTab(e, targetTool) {
    if (!tools.filter(tool => tool.urlSegment === targetTool.urlSegment && tool.type === "accessible").length)
      return e.preventDefault();

    setSelectedTool(targetTool);
    navigate(`/mon-compte/mes-outils/${targetTool.urlSegment}`);
  }

  return (
    <section className='container'>
      <div className='account-tools-page-container'>
        <div className='tools-sidebar'>
          <h2 className='tools-title'>Mes outils</h2>
          <Hr margin='20px 0' />
          <ul className='tools-tabs'>
            {tools.map((tool, index) => (
              <li key={index} className='tool-tab-container'>
                <Link
                  onClick={e => navigateToTab(e, tool)}
                  to={`/mon-compte/mes-outils/${tool.urlSegment}`}
                  className={`tool-tab ${selectedTool.name === tool.name ? "active" : ""} ${
                    tool.type === "comingSoon" ? "disabled" : ""
                  }`}>
                  <div>
                    {renderIcon(tool)}
                    {tool.name}
                  </div>
                </Link>
                {tool.type === "comingSoon" && <div className='soon-tag'>Bientôt</div>}
              </li>
            ))}
          </ul>
        </div>
        <div className='tool-content'>{renderToolContent(selectedTool)}</div>
      </div>
    </section>
  );
}

export default AccountTools;
