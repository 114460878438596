import React, { useState, useContext, useEffect } from "react";
import { set, useForm } from "react-hook-form";
import "./CreditSimulatorForm.css";
import NumberInput from "../../molecules/formComponents/numberInput/NumberInput";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import Input from "../../molecules/formComponents/input/Input";
import UnfilledButton from "../../molecules/buttons/unfilledButton/UnfilledButton";
import {
  createCreditSimulatorApi,
  fetchCreditSimulatorApi,
  updateCreditSimulatorApi,
} from "../../../api/CreditSimulatorApi";
import AppContext from "../../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { CreditSimulatorContext } from "./creditSimulatorContext/CreditSimulatorContext";
import { removeSpaces } from "../../../utils/Utils";

function CreditSimulatorForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    unregister,
    watch,
    getValues,
  } = useForm();
  const inputForm = { register, setValue, unregister, watch };
  const { createNotification, setAppLoaderVisible } = useContext(AppContext);
  const [isSaving, setIsSaving] = useState(false);
  const { simulatorIdParam } = useParams();
  const { getUuid } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (simulatorIdParam) {
      fetchCreditSimulator(simulatorIdParam);
    }
  }, [simulatorIdParam]);

  async function fetchCreditSimulator(creditSimulatorId) {
    try {
      const { data: creditSimulator } = await fetchCreditSimulatorApi(creditSimulatorId);

      if (creditSimulator) {
        const formattedDate = formatDate(creditSimulator.credit_simulator.date_depart);
        setValue("credit_simulator_name", creditSimulator.credit_simulator.credit_name);
        setValue("montantPret", creditSimulator.credit_simulator.montant_pret);
        setValue("tauxHorsAssurance", creditSimulator.credit_simulator.taux_hors_assurance);
        setValue("tauxAssurance", creditSimulator.credit_simulator.taux_assurance);
        setValue("duree", creditSimulator.credit_simulator.duree);
        setValue("dateDepart", formattedDate);
      }
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du chargement du tableau d'amortissement. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setAppLoaderVisible(false);
    }
  }

  async function onSubmit(data) {
    setIsSaving(true);
    try {
      if (!getUuid()) {
        localStorage.setItem("credit_simulator_form_data", JSON.stringify(data));
        createNotification(<>Vous devez être connecté pour sauvegarder un tableau d'amortissement.</>);
        return navigate("/connexion?redirect=/plan-financement", { replace: true });
      }

      if (simulatorIdParam) {
        const formattedData = {
          credit_name: data.credit_simulator_name,
          montant_pret: parseInt(removeSpaces(data.montantPret), 10),
          taux_hors_assurance: parseFloat(data.tauxHorsAssurance),
          taux_assurance: parseFloat(data.tauxAssurance),
          duree: parseInt(data.duree, 10),
          date_depart: data.dateDepart,
        };

        await updateCreditSimulatorApi(simulatorIdParam, formattedData);
      } else {
        const formattedData = {
          credit_name: data.credit_simulator_name,
          montant_pret: parseInt(removeSpaces(data.montantPret), 10),
          taux_hors_assurance: parseFloat(data.tauxHorsAssurance),
          taux_assurance: parseFloat(data.tauxAssurance),
          duree: parseInt(data.duree, 10),
          date_depart: data.dateDepart,
        };

        const res = await createCreditSimulatorApi(formattedData);
        const response = res.data;

        if (response.credit_simulator.credit_id) {
          const newSimulatorId = response.credit_simulator.credit_id;

          navigate(`/simulateur-credit/${newSimulatorId}`, { replace: true });
        } else {
          throw new Error(
            createNotification(
              <>Une erreur est survenue lors de la création du tableau d'amortissement. Veuillez réessayer.</>,
              "var(--red)",
              "var(--dark-blue)",
            ),
          );
        }
      }

      createNotification(
        <>Le tableau d'amortissement a été sauvegardé avec succès.</>,
        "var(--green)",
        "var(--dark-blue)",
      );
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la création du tableau d'amortissement. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setIsSaving(false);
    }
  }

  function formatDate(dateString) {
    const [day, month, year] = dateString.split(" ")[0].split("/");
    return `${year}-${month}-${day}`;
  }

  function resetCreditSimulatorForm() {
    reset({
      credit_simulator_name: "",
      montantPret: "",
      tauxHorsAssurance: "",
      tauxAssurance: "",
      duree: "",
      dateDepart: "",
    });
  }

  return (
    <CreditSimulatorContext.Provider value={{ simulatorIdParam, fetchCreditSimulator }}>
      <form onSubmit={handleSubmit(onSubmit)} className='credit-simulator-form'>
        <fieldset className='borderless-fieldset full-page-form'>
          <Input
            useForm={inputForm}
            label="Intitulé du tableau d'amortissement"
            bgColor='var(--pale-blue)'
            name='credit_simulator_name'
            required
            error={errors.credit_simulator_name?.message}
          />
          <NumberInput
            useForm={inputForm}
            icon='euro'
            name='montantPret'
            label='Montant du prêt *'
            required
            error={errors.montantPret?.message}
          />
          <div className='row-1000'>
            <NumberInput
              useForm={inputForm}
              icon='percent'
              name='tauxHorsAssurance'
              label='Taux hors assurance *'
              required
              error={errors.tauxHorsAssurance?.message}
            />
            <NumberInput
              useForm={inputForm}
              icon='percent'
              name='tauxAssurance'
              label='Taux assurance *'
              required
              error={errors.tauxAssurance?.message}
            />
          </div>
          <div className='row-1000'>
            <NumberInput
              useForm={inputForm}
              name='duree'
              label='Durée (années) *'
              required
              error={errors.duree?.message}
            />
            <Input
              type='date'
              useForm={inputForm}
              name='dateDepart'
              label="Date de départ du tableau d'amortissement *"
              required
              error={errors.dateDepart?.message}
            />
          </div>
          <div className='form-legend'>* Obligatoire</div>
          <div className='credit-simulator-form-buttons'>
            <UnfilledButton type='submit' padding='10px 25px' disabled={isSaving}>
              {isSaving ? "Enregistrement en cours..." : "Enregistrer"}
            </UnfilledButton>
            <FilledButton padding='10px 25px' disabled={true}>
              Télécharger
            </FilledButton>
          </div>
          <div className='reset-credit-simulator-form' onClick={resetCreditSimulatorForm}>
            <p>Réinitialiser le formulaire</p>
          </div>
        </fieldset>
      </form>
    </CreditSimulatorContext.Provider>
  );
}

export default CreditSimulatorForm;
