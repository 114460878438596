import React, { useContext, useState, useEffect } from "react";
import "./EditHeaderFooterColorReportForm.css";
import { useForm } from "react-hook-form";
import AppContext from "../../../../context/AppContext";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../../molecules/buttons/unfilledButton/UnfilledButton";
import { SketchPicker } from "react-color";

function EditHeaderFooterReportLogoForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { setModalVisible, createNotification } = useContext(AppContext);
  const defaultBannerBackgroundColor = "#252b41";
  const [bannerBackgroundColor, setBannerBackgroundColor] = useState(defaultBannerBackgroundColor);
  const [bannerTextColor, setBannerTextColor] = useState("#fffff"); // Texte blanc par défaut

  /*
  const data = {
    "banner_background_color" : "#00000",
    "banner_text_color": "#fffff",
  };
  */

  async function onSubmit(data) {
    //TODO envoi au back
  }

  function getContrastYIQ(hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "#000000" : "#FFFFFF";
  }

  function handleBackgroundColorChange(color) {
    const newColor = color.hex;
    setBannerBackgroundColor(newColor);
    const textColor = getContrastYIQ(newColor);
    setBannerTextColor(textColor);
  }

  function resetBackgroundColor() {
    setBannerBackgroundColor(defaultBannerBackgroundColor);
    const textColor = getContrastYIQ(defaultBannerBackgroundColor);
    setBannerTextColor(textColor);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='edit-report-logo-form'>
      <div className='edit-report-container'>
        <div className='preview-container'>
          <div className='header-banner-color' style={{ backgroundColor: bannerBackgroundColor }}></div>
          <div className='footer-banner-color' style={{ backgroundColor: bannerBackgroundColor }}>
            <p style={{ color: bannerTextColor }}>Page X/XX</p>
          </div>
        </div>
        <div className='color-picker-container'>
          <div className='color-picker-row'>
            <SketchPicker color={bannerBackgroundColor} onChangeComplete={handleBackgroundColorChange} />
            <a className='reset-color-button' onClick={resetBackgroundColor}>
              Réinitialiser la couleur
            </a>
          </div>
        </div>
      </div>
      {errors.reportLogo && <p className='error-message'>Ce champ est requis</p>}
      <div className='modal-buttons-row mt-md'>
        <UnfilledButton onClick={() => setModalVisible(false)}>Annuler</UnfilledButton>
        <FilledButton type='submit'>Enregistrer</FilledButton>
      </div>
    </form>
  );
}

export default EditHeaderFooterReportLogoForm;
