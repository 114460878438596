import React, { useContext, useState, useEffect } from "react";
import "./FolderDeliverables.css";
import Tuto from "../../../../../../../components/atoms/icons/general/tuto/Tuto";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../../../components/atoms/icons/general/add/Add";
import { useWindowSize } from "@uidotdev/usehooks";
import AppContext from "../../../../../../../context/AppContext";
import CreateLivrableModal from "../../../../../../../components/forms/modals/account/createLivrableModal/CreateLivrableModal";
import {
  deleteAllCreditSimulatorsFromDeliverableApi,
  deleteAllEstimationsFromDeliverableApi,
  deleteAllFinancingPlansFromDeliverableApi,
  deleteCreditSimulatorFromDeliverableApi,
  deleteDeliverableApi,
  deleteEstimationFromDeliverableApi,
  deleteFinancingPlanFromDeliverableApi,
  duplicateDeliverableApi,
  fetchDeliverablesByFolderIdApi,
} from "../../../../../../../api/FolderApi";
import Card from "../../../../../../../components/molecules/cards/card/Card";
import Hr from "../../../../../../../components/molecules/hr/Hr";
import Bracket from "../../../../../../../components/atoms/icons/general/bracket/Bracket";
import Pen from "../../../../../../../components/atoms/icons/account/pen/Pen";
import Trash from "../../../../../../../components/atoms/icons/general/trash/Trash";
import Deliverable from "../../../../../../../components/atoms/icons/folder/deliverable/Deliverable";
import UnfilledButton from "../../../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import AddEstimationToDeliverableModal from "../../../../../../../components/forms/modals/account/addEstimationToDeliverableModal/AddEstimationToDeliverableModal";
import UpdateDeliverableModal from "../../../../../../../components/forms/modals/account/updateDeliverableModal/UpdateDeliverableModal";
import AddFinancingPlanToDeliverableModal from "../../../../../../../components/forms/modals/account/addFinancingPlanToDeliverableModal/AddFinancingPlanToDeliverableModal";
import DuplicateDeliverableModal from "../../../../../../../components/forms/modals/account/duplicateDeliverableModal/DuplicateDeliverableModal";
import AddCreditSimulatorToDeliverableModal from "../../../../../../../components/forms/modals/account/addCreditSimulatorToDeliverableModal/AddCreditSimulatorToDeliverableModal";
import { Link } from "react-router-dom";

function FolderDeliverables({ folder, triggerFolderUpdate, showTooltip = () => {} }) {
  const { setModalContent, setModalVisible, createNotification } = useContext(AppContext);
  const { width } = useWindowSize();
  const [livrables, setLivrables] = useState([]);
  const [openedEstimationIndexes, setOpenedEstimationIndexes] = useState({});
  const [openedFinancingPlanIndexes, setOpenedFinancingPlanIndexes] = useState({});
  const [openedCreditSimulatorsIndexes, setOpenedCreditSimulatorsIndexes] = useState({});
  const [openedLivrables, setOpenedLivrables] = useState({});

  useEffect(() => {
    if (folder) fetchAlllDeliverables();
  }, [folder]);

  // Deliverables

  async function fetchAlllDeliverables() {
    try {
      const res = (await fetchDeliverablesByFolderIdApi(folder.data.folder_id)).data;

      const extractedLivrables = res.map(item => ({
        id: item.deliverable.data.deliverable_id,
        name: item.deliverable.data.deliverable_name,
        estimations: [
          ...item.deliverable.estimations.fonds_de_commerce,
          ...item.deliverable.estimations.titres_de_societe,
          ...item.deliverable.estimations.murs_commerciaux,
        ],
        financing_plans: [
          ...item.deliverable.financing_plans.financing_plan_fdc,
          ...item.deliverable.financing_plans.financing_plan_murs,
        ],
        credit_simulators: item.deliverable.credit_simulators.map(simulator => simulator.credit_simulator),
      }));

      setLivrables(extractedLivrables || []);
    } catch (error) {
      setLivrables([]);
    }
  }

  function openCreateDeliverableModal() {
    setModalContent({
      title: "Créer un livrable",
      content: (
        <CreateLivrableModal
          folder={folder}
          folderId={folder.data.folder_id}
          onLivrableCreated={() => triggerDeliverableUpdate()}
        />
      ),
    });
    setModalVisible(true);
  }

  async function handleDeleteDeliverable(deliverableId) {
    try {
      await deleteDeliverableApi(deliverableId);
      const updatedLivrables = livrables.filter(livrable => livrable.id !== deliverableId);
      setLivrables(updatedLivrables);
      updateFolderInState({
        ...folder,
        livrables: updatedLivrables,
        total_deliverables: updatedLivrables.length,
      });

      createNotification(<>Le livrable a été supprimé avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression du livrable</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function handleDuplicateDeliverable(deliverable) {
    setModalContent({
      title: "Dupliquer le livrable",
      content: (
        <DuplicateDeliverableModal
          deliverableId={deliverable.id}
          currentName={deliverable.name}
          onDuplicateSuccess={() => triggerDeliverableUpdate()}
        />
      ),
    });
    setModalVisible(true);
  }

  function handleModifyDeliverable(deliverable) {
    setModalContent({
      title: "Modifier le livrable",
      content: <UpdateDeliverableModal deliverable={deliverable} onUpdateSuccess={() => triggerDeliverableUpdate()} />,
    });
    setModalVisible(true);
  }

  function triggerDeliverableUpdate() {
    fetchAlllDeliverables();
    triggerFolderUpdate();
  }

  // Estimations

  function setOpenedEstimationIndex(key, visible = true) {
    setOpenedEstimationIndexes(indexes => {
      return { ...indexes, [key]: visible };
    });
  }

  function handleAddEstimationToDeliverable(deliverableId) {
    const livrable = livrables.find(livrable => livrable.id === deliverableId);
    setModalContent({
      title: "Ajouter une/des estimation(s) au livrable : " + livrable.name,
      content: (
        <AddEstimationToDeliverableModal
          folder={folder}
          deliverableId={deliverableId}
          onEstimationsAdded={() => triggerDeliverableUpdate()}
        />
      ),
    });
    setModalVisible(true);
  }

  async function handleDeleteEstimation(deliverableId, estimationId) {
    try {
      await deleteEstimationFromDeliverableApi(deliverableId, estimationId);

      triggerDeliverableUpdate();

      createNotification(<>L'estimation a été supprimée avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression de l'estimation</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function handleDeleteAllEstimationDeliverable(deliverableId) {
    try {
      await deleteAllEstimationsFromDeliverableApi(deliverableId);

      triggerDeliverableUpdate();

      createNotification(
        <>Toutes les estimations ont été supprimées avec succès</>,
        "var(--green)",
        "var(--dark-blue)",
      );
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression des estimations</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  // Financing Plan

  function setOpenedFinancingPlanIndex(key, visible = true) {
    setOpenedFinancingPlanIndexes(indexes => {
      return { ...indexes, [key]: visible };
    });
  }

  function handleAddFinancingPlanToDeliverable(deliverableId) {
    const livrable = livrables.find(livrable => livrable.id === deliverableId);
    setModalContent({
      title: "Ajouter un/des plan(s) de financement au livrable : " + livrable.name,
      content: (
        <AddFinancingPlanToDeliverableModal
          folder={folder}
          deliverableId={deliverableId}
          onFinancingPlansAdded={() => triggerDeliverableUpdate()}
        />
      ),
    });
    setModalVisible(true);
  }

  async function handleDeleteFinancingPlan(deliverableId, financingPlanId) {
    try {
      await deleteFinancingPlanFromDeliverableApi(deliverableId, financingPlanId);

      triggerDeliverableUpdate();

      createNotification(<>Le plan de financement a été supprimé avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression du plan de financement</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function handleDeleteAllFinancingPlansFromDeliverable(deliverableId) {
    try {
      await deleteAllFinancingPlansFromDeliverableApi(deliverableId);

      triggerDeliverableUpdate();

      createNotification(
        <>Tous les plans de financement ont été supprimés avec succès</>,
        "var(--green)",
        "var(--dark-blue)",
      );
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression des plans de financement</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  // Credit Simulator

  function setOpenedCreditSimulatorIndex(key, visible = true) {
    setOpenedCreditSimulatorsIndexes(indexes => {
      return { ...indexes, [key]: visible };
    });
  }

  function handleAddCreditSimulatorToDeliverable(deliverableId) {
    const livrable = livrables.find(livrable => livrable.id === deliverableId);

    setModalContent({
      title: "Ajouter un/des tableau(x) d'amortissement au livrable : " + livrable.name,
      content: (
        <AddCreditSimulatorToDeliverableModal
          folder={folder}
          deliverableId={deliverableId}
          onCreditSimulatorsAdded={() => triggerDeliverableUpdate()}
        />
      ),
    });
    setModalVisible(true);
  }

  async function handleDeleteCreditSimulator(deliverableId, creditSimulatorId) {
    try {
      await deleteCreditSimulatorFromDeliverableApi(deliverableId, creditSimulatorId);

      triggerDeliverableUpdate();

      createNotification(<>Le tableau d'amortissement a été supprimé avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression du tableau d'amortissement</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function handleDeleteAllCreditSimulatorsFromDeliverable(deliverableId) {
    try {
      await deleteAllCreditSimulatorsFromDeliverableApi(deliverableId);

      triggerDeliverableUpdate();

      createNotification(
        <>Les tableaux d'amortissement ont été supprimés avec succès</>,
        "var(--green)",
        "var(--dark-blue)",
      );
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression des plans de financement</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  // Utils

  function toggleLivrable(id) {
    setOpenedLivrables(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }

  return (
    <div>
      <div className='tab-content-header'>
        <h2>
          <Deliverable />
          Livrables
          {/*    
          <span className='tuto-icon'>
            <Tuto onClick={() => showTooltip("")} />
          </span>
          */}
        </h2>
        <p>
          Constituez votre ou vos livrables pour ce dossier. Vous pouvez télécharger un livrable seul ou tout le
          dossier.
        </p>
      </div>
      <div className='folder-deliverable-buttons'>
        <FilledButton onClick={openCreateDeliverableModal}>
          <Add width={width > 576 ? "30px" : "20px"} /> Créer un livrable
        </FilledButton>
      </div>
      <div className='deliverables-grid mt-md'>
        {livrables?.length > 0 ? (
          livrables.map(livrable => (
            <Card key={livrable?.id} className='deliverable-item'>
              <div className='deliverable-item-head'>
                <div className='deliverable-name'>
                  <h3>
                    <Deliverable />
                    {livrable.name}
                  </h3>
                  <Bracket
                    rotation={openedLivrables[livrable?.id] ? "180deg" : "90deg"}
                    width='20px'
                    color='var(--dark-blue)'
                    className='bracket-toggle ml-sm mt-xs'
                    onClick={() => toggleLivrable(livrable?.id)}
                  />
                </div>
                <div className='deliverable-actions-buttons'>
                  <FilledButton
                    bgColor='#E6CC83'
                    hoverColor='#E6CC83'
                    onClick={() => handleModifyDeliverable(livrable)}>
                    Renommer
                  </FilledButton>
                  <FilledButton bgColor='#B8C7FF' hoverColor='#B8C7FF'>
                    Télécharger
                  </FilledButton>
                  <FilledButton
                    bgColor='#E3E3E3'
                    hoverColor='#E3E3E3'
                    onClick={() => handleDuplicateDeliverable(livrable)}>
                    Dupliquer
                  </FilledButton>
                  <FilledButton
                    bgColor='#FDB7A9'
                    hoverColor='#FDB7A9'
                    onClick={() => handleDeleteDeliverable(livrable?.id)}>
                    Supprimer
                  </FilledButton>
                </div>
              </div>
              {openedLivrables[livrable?.id] && (
                <>
                  <div className='deliverable-content-container'>
                    <div className='w-100'>
                      <Hr margin='20px 0' />
                    </div>
                    <div className='deliverable-estimations-container'>
                      <h4 className='deliverable-content-header'>Estimation(s) du livrable :</h4>
                      <ul>
                        {Array.isArray(livrable?.estimations) && livrable.estimations.length > 0 ? (
                          livrable.estimations.map(estimation => {
                            const estimationKey = `${livrable.id}-${estimation.id}`;
                            return (
                              <div key={estimationKey} className='estimation-item'>
                                <p>{estimation?.infos?.nom || estimation?.infos?.enseigne}</p>
                                <div
                                  className='estimation-dropdown'
                                  onClick={() =>
                                    setOpenedEstimationIndex(estimationKey, !openedEstimationIndexes[estimationKey])
                                  }>
                                  <Bracket
                                    rotation={openedEstimationIndexes[estimationKey] ? "0" : "180deg"}
                                    width='15px'
                                    color='var(--white)'
                                    className='dropdown-icon-estimations'
                                  />
                                  <ul
                                    className={`dropdown-menu ${openedEstimationIndexes[estimationKey] ? "show" : ""}`}>
                                    <li>
                                      <Link to={`/synthese/${estimation.id}`}>
                                        <Pen width='22px' /> Modifier
                                      </Link>
                                    </li>
                                    <li>
                                      <button onClick={() => handleDeleteEstimation(livrable?.id, estimation?.id)}>
                                        <Trash />
                                        Retirer du livrable
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <li>Aucune estimation associée</li>
                        )}
                      </ul>
                      <div className='deliverable-estimations-actions-button'>
                        <UnfilledButton
                          className='add-estimation-button'
                          onClick={() => handleAddEstimationToDeliverable(livrable?.id)}>
                          Ajouter une estimation
                        </UnfilledButton>
                        {livrable.estimations.length > 0 && (
                          <UnfilledButton
                            className='add-estimation-button'
                            bgColor='var(--dark-red)'
                            onClick={() => handleDeleteAllEstimationDeliverable(livrable?.id)}>
                            {livrable.estimations.length > 1
                              ? "Supprimer toutes les estimations"
                              : "Supprimer l'estimation"}
                          </UnfilledButton>
                        )}
                      </div>
                    </div>
                    <div className='w-100'>
                      <Hr margin='20px 0' />
                    </div>
                    <div className='deliverable-financingplans-container'>
                      <h4 className='deliverable-content-header'>Plans(s) de financement du livrable :</h4>
                      <ul>
                        {Array.isArray(livrable?.financing_plans) && livrable.financing_plans.length > 0 ? (
                          livrable.financing_plans.map(financingPlan => {
                            const financingPlanId = financingPlan.financing_plan.financing_plan_id;
                            const financingPlanKey = `${livrable.id}-${financingPlanId}`;
                            const financing_plan = financingPlan.financing_plan;

                            return (
                              <div key={financingPlanKey} className='financingplan-item'>
                                <p>{financing_plan.financing_plan_name}</p>
                                <div
                                  className='financingplan-dropdown'
                                  onClick={() =>
                                    setOpenedFinancingPlanIndex(
                                      financingPlanKey,
                                      !openedFinancingPlanIndexes[financingPlanKey],
                                    )
                                  }>
                                  <Bracket
                                    rotation={openedFinancingPlanIndexes[financingPlanKey] ? "0" : "180deg"}
                                    width='15px'
                                    color='var(--white)'
                                    className='dropdown-icon-financingplans'
                                  />
                                  <ul
                                    className={`dropdown-menu ${openedFinancingPlanIndexes[financingPlanKey] ? "show" : ""}`}>
                                    <li>
                                      <Link
                                        to={`/plan-financement/${financingPlan.financing_plan.financing_plan_type}/${financingPlanId}`}>
                                        <Pen width='22px' />
                                        Modifier
                                      </Link>
                                    </li>
                                    <li>
                                      <button onClick={() => handleDeleteFinancingPlan(livrable.id, financingPlanId)}>
                                        <Trash />
                                        Retirer du livrable
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <li>Aucun plan de financement associé</li>
                        )}
                      </ul>
                      <div className='deliverable-financingplans-actions-button'>
                        <UnfilledButton
                          className='add-financingplan-button'
                          onClick={() => handleAddFinancingPlanToDeliverable(livrable?.id)}>
                          Ajouter un plan de financement
                        </UnfilledButton>
                        {livrable.financing_plans.length > 0 && (
                          <UnfilledButton
                            className='delete-financingplan-button'
                            bgColor='var(--dark-red)'
                            onClick={() => handleDeleteAllFinancingPlansFromDeliverable(livrable?.id)}>
                            {livrable.financing_plans.length > 1
                              ? "Supprimer tous les plans de financement"
                              : "Supprimer le plan de financement"}
                          </UnfilledButton>
                        )}
                      </div>
                    </div>
                    <div className='w-100'>
                      <Hr margin='20px 0' />
                    </div>
                    <div className='deliverable-financingplans-container'>
                      <h4 className='deliverable-content-header'>Tableau(x) d'amortissement du livrable :</h4>
                      <ul>
                        {Array.isArray(livrable?.credit_simulators) && livrable.credit_simulators.length > 0 ? (
                          livrable.credit_simulators.map(creditSimulator => {
                            const creditSimulatorId = creditSimulator.credit_id;
                            const creditSimulatorKey = `${livrable.id}-${creditSimulatorId}`;

                            return (
                              <div key={creditSimulatorKey} className='financingplan-item'>
                                <p>{creditSimulator.credit_name}</p>
                                <div
                                  className='financingplan-dropdown'
                                  onClick={() =>
                                    setOpenedCreditSimulatorIndex(
                                      creditSimulatorKey,
                                      !openedCreditSimulatorsIndexes[creditSimulatorKey],
                                    )
                                  }>
                                  <Bracket
                                    rotation={openedCreditSimulatorsIndexes[creditSimulatorKey] ? "0" : "180deg"}
                                    width='15px'
                                    color='var(--white)'
                                    className='dropdown-icon-financingplans'
                                  />
                                  <ul
                                    className={`dropdown-menu ${openedCreditSimulatorsIndexes[creditSimulatorKey] ? "show" : ""}`}>
                                    <li>
                                      <Link to={`/simulateur-credit/${creditSimulatorId}`}>
                                        <Pen width='22px' />
                                        Modifier
                                      </Link>
                                    </li>
                                    <li>
                                      <button
                                        onClick={() => handleDeleteCreditSimulator(livrable.id, creditSimulatorId)}>
                                        <Trash />
                                        Retirer du livrable
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <li>Aucun tableau d'amortissement associé</li>
                        )}
                      </ul>
                      <div className='deliverable-financingplans-actions-button'>
                        <UnfilledButton
                          className='add-financingplan-button'
                          onClick={() => handleAddCreditSimulatorToDeliverable(livrable?.id)}>
                          Ajouter un tableau d'amortissement
                        </UnfilledButton>
                        {livrable.credit_simulators.length > 0 && (
                          <UnfilledButton
                            className='delete-financingplan-button'
                            bgColor='var(--dark-red)'
                            onClick={() => handleDeleteAllCreditSimulatorsFromDeliverable(livrable?.id)}>
                            {livrable.financing_plans.length > 1
                              ? "Supprimer tous les tableaux d'amortissement"
                              : "Supprimer le tableau d'amortissement"}
                          </UnfilledButton>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Card>
          ))
        ) : (
          <div className='info-block-deliverable'>
            <p>Aucun livrable dans ce dossier</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default FolderDeliverables;
