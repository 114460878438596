import React, { useContext, useEffect, useState } from "react";
import "./FolderInfo.css";
import { useForm } from "react-hook-form";
import { defaultValidation, max2250Validation } from "../../../../../../../utils/formValidation/FormValidation";
import { updateFolderApi } from "../../../../../../../api/FolderApi";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import Input from "../../../../../../../components/molecules/formComponents/input/Input";
import Textarea from "../../../../../../../components/molecules/formComponents/textarea/Textarea";
import AppContext from "../../../../../../../context/AppContext";
import Tuto from "../../../../../../../components/atoms/icons/general/tuto/Tuto";
import Dashboard from "../../../../../../../components/atoms/icons/folder/dashboard/Dashboard";

function FolderInfo({ folder, triggerFolderUpdate, setSelectedTab, showTooltip = () => {} }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { createNotification } = useContext(AppContext);

  useEffect(() => {
    if (folder) {
      setValue("folder_name", folder.data.folder_name);
      setValue("description", folder.data.description);
    }
  }, [folder, setValue]);

  async function updateFolderInfo(data) {
    try {
      await updateFolderApi(folder.data.folder_id, 1, data.folder_name, data.description);

      triggerFolderUpdate();

      createNotification(<>Le dossier a été mis à jour avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la mise à jour du dossier</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function calculateTotalEstimations(estimations) {
    if (!estimations) return 0;

    if (Array.isArray(estimations)) {
      return estimations.length;
    } else if (typeof estimations === "object") {
      return Object.values(estimations).reduce((acc, category) => {
        if (Array.isArray(category)) {
          return acc + category.length;
        }
        return acc;
      }, 0);
    }

    return 0;
  }

  function calculateTotalFinancingPlans(financingPlans) {
    if (!financingPlans) return 0;

    if (Array.isArray(financingPlans)) {
      return financingPlans.length;
    } else if (typeof financingPlans === "object") {
      return Object.values(financingPlans).reduce((acc, category) => {
        if (Array.isArray(category)) {
          return acc + category.length;
        }
        return acc;
      }, 0);
    }

    return 0;
  }

  function calculateTotalCreditSimulators(creditSimulators) {
    if (!creditSimulators) return 0;

    return creditSimulators.length;
  }

  function getFolderCreationDate(folderData) {
    return folderData.date_creation.substring(0, 10);
  }

  return (
    <>
      <div className='tab-content-header'>
        <div className='title-ref-tab-header'>
          <h2>
            <Dashboard width='20px' />
            Tableau de bord
            {/* 
            <span className='tuto-icon'>
              <Tuto onClick={() => showTooltip("")} />
            </span>
            */}
          </h2>
          <p className='ref-folder'>Référence dossier : {folder.data.reference}</p>
        </div>
        <p>Retrouvez ici toutes les informations relatives à votre dossier.</p>
      </div>
      <div className='crm-dashboard'>
        <form onSubmit={handleSubmit(updateFolderInfo)} className='crm-card form-card'>
          <div>
            <h4>
              Informations
              <span className='tooltip' data-tooltip='Informations générales sur votre dossier'>
                ?
              </span>
            </h4>
            <Input
              label='Nom du dossier'
              validation={defaultValidation}
              useForm={{ register, setValue }}
              error={errors.folder_name?.message}
              name='folder_name'
            />
            <Textarea
              label='Description du dossier'
              validation={max2250Validation}
              useForm={{ register, setValue }}
              error={errors.description?.message}
              name='description'
              resizable={false}
            />
            <p>Dossier créé le : {getFolderCreationDate(folder.data)}</p>
          </div>
          <div className='folder-info-buttons'>
            <FilledButton type='submit'>Sauvegarder</FilledButton>
          </div>
        </form>

        <div className='cards-stack'>
          <div className='crm-card cursor-pointer' onClick={() => setSelectedTab("estimations")}>
            <h4>
              Estimations
              <span className='tooltip' data-tooltip='Toutes les estimations liées à votre dossier'>
                ?
              </span>
            </h4>
            <p>
              <span className='number-count'>{calculateTotalEstimations(folder.estimations)}</span>
            </p>
            <p>{calculateTotalEstimations(folder.estimations) > 1 ? "estimations" : "estimation"} dans ce dossier</p>
          </div>

          <div className='crm-card cursor-pointer' onClick={() => setSelectedTab("financingPlan")}>
            <h4>
              Plans de financement
              <span className='tooltip' data-tooltip='Tous les plans de financement liés à votre dossier'>
                ?
              </span>
            </h4>
            <p>
              <span className='number-count'>{calculateTotalFinancingPlans(folder.financing_plans)}</span>
            </p>
            <p>
              {calculateTotalFinancingPlans(folder.financing_plans) > 1 ? "plans" : "plan"} de financement dans ce
              dossier
            </p>
          </div>

          <div className='crm-card cursor-pointer' onClick={() => setSelectedTab("creditSimulation")}>
            <h4>
              Tableaux d'amortissement
              <span className='tooltip' data-tooltip="Tous les tableaux d'amortissement liés dans votre dossier">
                ?
              </span>
            </h4>
            <p>
              <span className='number-count'>{calculateTotalCreditSimulators(folder.credit_simulators)}</span>
            </p>
            <p>
              {calculateTotalCreditSimulators(folder.credit_simulators) > 1 ? "simulateurs" : "simulateur"} de crédit
              dans ce dossier
            </p>
          </div>

          <div className='crm-card cursor-pointer' onClick={() => setSelectedTab("livrables")}>
            <h4>
              Livrables
              <span className='tooltip' data-tooltip='Tous les livrables créés dans votre dossier'>
                ?
              </span>
            </h4>
            <p>
              <span className='number-count'>{folder.data.total_deliverables}</span>
            </p>
            <p>{folder.data.total_deliverables > 1 ? "livrables" : "livrable"} dans ce dossier</p>
          </div>

          {/*
            TODO : ajouter la page d'aide pour les dossiers
            
            <div className='crm-card'>
              <h4>
                Aide
                <span className='tooltip' data-tooltip='Ressources pour vous aider à constituer votre dossier'>
                  ?
                </span>
              </h4>
              <p>Retrouvez nos aides pour constituer un dossier de qualité et augmenter vos chances de financement.</p>
            </div>
          */}
        </div>
      </div>
    </>
  );
}

export default FolderInfo;
