import { createContext, useContext } from "react";
import { formatNumberWithSpaces } from "../utils/Utils";
import { PdfContext } from "./PdfContext";
import H3Pdf from "../components/pdfComponents/h3Pdf/H3Pdf";

const PdfTdcFdcContext = createContext();

export function usePdfTdsFdcContext() {
  return useContext(PdfTdcFdcContext);
}

export function PdfTdsFdcProvider({ children }) {
  const { config } = useContext(PdfContext);

  function formatDataForFinancialTable(estimationData, rapportData, financialElements, setData) {
    const result = {
      thead: ["", ...estimationData.map(year => year.year)],
      tbody: [],
    };

    financialElements.forEach(row => {
      if (row.keys) {
        if (row.keys[0] === "ca") {
          result.tbody.push({
            type: 1,
            cells: [row.label, ...rapportData.graphs.evolution_activite.total_ca.filter(value => value)],
          });
        } else
          result.tbody.push({
            type: 1,
            cells: [row.label, ...estimationData.map(year => row.keys.reduce((acc, key) => acc[key], year))],
          });
      } else if (row.substraction) {
        result.tbody.push({
          type: 2,
          cells: [
            row.label,
            ...estimationData.map((_, index) =>
              row.substraction.reduce(
                (acc, value) => acc - result.tbody[value].cells[index + 1],
                result.tbody[row.substraction[0]].cells[index + 1] * 2,
              ),
            ),
          ],
        });
      } else
        result.tbody.push({
          type: 4,
          cells: [row.label],
          fullLine: true,
        });
    });

    result.tbody.at(-1).type = 3;
    result.tbody.at(10).type = 2;

    estimationData.forEach((_, index) => {
      const values = result.tbody.map(row => row.cells[index + 1]);
      let total = 0;
      total += values[4] - values[15] - values[5] - values[7] - values[8] - values[9] + values[9];
    });
    result.tbody = result.tbody.map(row => {
      return {
        type: row.type,
        cells: row.cells.map((cell, index) => {
          if (index) cell = cell === 0 ? "0 €" : formatNumberWithSpaces(cell, false, "€");
          return cell;
        }),
        fullLine: row.fullLine,
      };
    });

    setData(result);
  }

  function getBesoinDiplomeDescription(estimationData) {
    return estimationData.diplome_certification.description === "Oui"
      ? "L'activité nécessite un diplôme, une certification, une carte professionnelle ou une licence (hormis Lic III et IV et agréments buraliste)"
      : "L'activité ne nécessite pas un diplôme, une certification, une carte professionnelle ou une licence (hormis Lic III et IV et agréments buraliste)";
  }

  function formatDataForResultatNetChart(estimationData, setData) {
    if (!estimationData) return null;

    const result = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            config.chartsColor.year_1.background,
            config.chartsColor.year_2.background,
            config.chartsColor.year_3.background,
            config.chartsColor.year_4.background,
          ],
          borderColor: [
            config.chartsColor.year_1.border,
            config.chartsColor.year_2.border,
            config.chartsColor.year_3.border,
            config.chartsColor.year_4.border,
          ],
          borderWidth: 4,
          borderRadius: 0,
          borderSkipped: false,
        },
      ],
    };

    estimationData.forEach(year => {
      result.labels.push(year.year);
      result.datasets[0].data.push(year.resultat_net.resultat_net_comptable);
    });

    setData(result);
  }

  function formatDataForVolumeAffairesChart(estimationData, rapportData, setData) {
    if (!estimationData || !rapportData) return null;

    setData({
      labels: estimationData.map(year => year.year),
      datasets: [
        {
          data: rapportData.graphs.evolution_activite.total_ca,
          backgroundColor: [
            config.chartsColor.year_1.background,
            config.chartsColor.year_2.background,
            config.chartsColor.year_3.background,
            config.chartsColor.year_4.background,
          ],
          borderColor: [
            config.chartsColor.year_1.border,
            config.chartsColor.year_2.border,
            config.chartsColor.year_3.border,
            config.chartsColor.year_4.border,
          ],
          borderWidth: 4,
          borderRadius: 0,
          borderSkipped: false,
        },
      ],
    });
  }

  function formatDataForDoughnutChart(rapportData, setData) {
    if (!rapportData) return null;

    setData({
      turnover: rapportData.graphs.taux_effort_masse_salariale.total_ca,
      payroll: rapportData.graphs.taux_effort_masse_salariale.masse_salariale,
      rentAndCharges: rapportData.graphs.taux_effort_masse_salariale.loyer_charges,
      performanceYear: "",
    });
  }

  function formatDataForActiviteChart(estimationData, rapportData, setData) {
    const result = {
      labels: [],
      datasets: [
        {
          id: 1,
          label: "Résultat net",
          backgroundColor: config.chartsColor.color_1,
          borderColor: config.chartsColor.color_1,
          fill: false,
          data: [],
          tension: 0.4,
        },
        {
          id: 2,
          label: "EBE retraité",
          backgroundColor: config.chartsColor.color_2,
          borderColor: config.chartsColor.color_2,
          fill: false,
          data: [],
          tension: 0.4,
        },
        {
          id: 3,
          label: "Valeur ajoutée",
          backgroundColor: config.chartsColor.color_3,
          borderColor: config.chartsColor.color_3,
          fill: false,
          data: rapportData.graphs.evolution_activite.valeur_ajoutee,
          tension: 0.4,
        },
        {
          id: 4,
          label: "Marge brute",
          backgroundColor: config.chartsColor.color_4,
          borderColor: config.chartsColor.color_4,
          fill: false,
          data: rapportData.graphs.evolution_activite.marge_brute,
          tension: 0.4,
        },
        {
          id: 5,
          label: "Produits d'exploitation",
          backgroundColor: config.chartsColor.color_5,
          borderColor: config.chartsColor.color_5,
          fill: false,
          data: rapportData.graphs.evolution_activite.total_ca,
          tension: 0.4,
        },
      ],
    };

    estimationData.forEach(year => {
      result.labels.push(year.year);
      result.datasets[0].data.push(year.resultat_net.resultat_net_comptable);
      result.datasets[1].data.push(year.retraitements.ebe);
    });

    setData(result);
  }

  function formatDataForMargeBruteChart(estimationData, rapportData, setData) {
    if (!estimationData || !rapportData) return null;

    setData({
      labels: estimationData.map(year => year.year),
      datasets: [
        {
          data: rapportData.graphs.evolution_activite.marge_brute,
          backgroundColor: [
            config.chartsColor.year_1.background,
            config.chartsColor.year_2.background,
            config.chartsColor.year_3.background,
            config.chartsColor.year_4.background,
          ],
          borderColor: [
            config.chartsColor.year_1.border,
            config.chartsColor.year_2.border,
            config.chartsColor.year_3.border,
            config.chartsColor.year_4.border,
          ],
          borderWidth: 4,
          borderRadius: 0,
          borderSkipped: false,
        },
      ],
    });
  }

  function formatDataForActiviteTable(activiteChartData, setData) {
    const result = {
      thead: ["", ...activiteChartData.labels],
      tbody: [
        ...activiteChartData.datasets.map(dataset => {
          return {
            id: dataset.id,
            type: dataset.id === 2 ? 3 : 1,
            cells: ["", ...dataset.data.map(value => formatNumberWithSpaces(value, true, "€"))],
          };
        }),
      ],
    };

    result.tbody = [
      result.tbody.find(row => row.id === 5),
      result.tbody.find(row => row.id === 4),
      result.tbody.find(row => row.id === 3),
      result.tbody.find(row => row.id === 1),
      result.tbody.find(row => row.id === 2),
    ];

    result.tbody[0].cells[0] = "Total HT des produits d'exploitation";
    result.tbody[1].cells[0] = "Marge brute";
    result.tbody[2].cells[0] = "Valeur ajoutée";
    result.tbody[3].cells[0] = "Résultat net";
    result.tbody[4].cells[0] = "Rentabilité retraitée";

    setData(result);
  }

  function formatDataForRentabiliteChart(estimationData, rapportData, setData) {
    const result = {
      labels: [],
      datasets: [
        {
          label: "EBE retraité",
          backgroundColor: config.chartsColor.color_2,
          borderColor: config.chartsColor.color_2,
          fill: false,
          data: [],
          tension: 0.4,
        },
        {
          label: "Total du CA HT",
          backgroundColor: config.chartsColor.color_5,
          borderColor: config.chartsColor.color_5,
          fill: false,
          data: [],
          tension: 0.4,
        },
      ],
    };

    estimationData.forEach(year => {
      result.labels.push(year.year);
      result.datasets[0].data.push(year.retraitements.ebe);
    });
    result.datasets[1].data.push(...rapportData.graphs.evolution_activite.total_ca);

    setData(result);
  }

  function formatDataForPotentielCroissance(mainYear, rapportData, setData) {
    if (!mainYear || !rapportData) return null;

    setData({
      percentage: mainYear.ratios.potentiel_ca_3_ans,
      euroAmount: rapportData.graphs.potentiel_ca,
      percentageText: "Pourcentage estimé d'évolution des produits d'exploitation sur 3 ans",
      euroAmountText: "Montant estimé d'évolution des produits d'exploitation sur 3 ans",
    });
  }

  function formatDataForRhTable(mainYear, setData) {
    if (!mainYear?.ratios?.detail_rh.length) return setData(null);

    const result = {
      thead: ["Personnel", "Poste", "Date\nd'entrée", "Type de\ncontrat", "Rémunération\nannuelle"],
      tbody: [],
    };
    mainYear?.ratios?.detail_rh?.forEach(rh => {
      result.tbody.push({
        type: rh.is_key ? 2 : 1,
        cells: [
          rh.nom,
          rh.poste,
          rh.annee_entree,
          rh.type_contrat,
          formatNumberWithSpaces(rh.salaire_brut_annuel, true, "€"),
        ],
      });
    });

    setData(result);
  }

  const Introduction = (
    <>
      <H3Pdf title='Préambule' />
      <div className='pdf-text-container'>
        <p>
          Ce rapport a pour objectif d'évaluer l'affaire sur la base d'éléments financiers, ainsi que toutes les
          facettes de l'exploitation, incluant ses forces et faiblesses.
        </p>
        <p>
          Cette estimation a été réalisée avec une vision opérationnelle, en intégrant chiffre d'affaires, rentabilité,
          rayonnement, état locatif, dynamique commerciale, aspects logistiques, ratios clefs, valeur ajoutée de
          l'exploitant et éléments d'actif et passif du bilan.
        </p>
        <p>L'évaluation d'une affaire est étroitement liée à sa potentialité de transmission.</p>
        <p>
          L'expérience du terrain nous a appris que la valeur d'une affaire ne tient pas qu'à des ratios comptables ou
          aux seuls souhaits du cédant.
        </p>
        <p>
          Elle doit constituer un juste équilibre entre ceux-ci et de nombreux autres facteurs, et assurer la
          finançabilité du projet pour de potentiels repreneurs.
        </p>
        <p>Une affaire peut être transmise sous forme de fonds de commerce ou de titres de société.</p>
        <p>Ce rapport présente l'évaluation de l'affaire en 4 parties :</p>
        <ul className='dots-list'>
          <li>Les éléments financiers;</li>
          <li>Les éléments d’exploitation;</li>
          <li>L’évaluation;</li>
          <li>Les annexes;</li>
        </ul>
        <p>
          La fourchette de valeurs indiquée dans ce rapport ne constitue qu'une base de départ dans un éventuel projet
          de transmission, dont la valeur finale négociée entre cédant et repreneur peut être très différente.
        </p>
        <p>Le présent rapport et son contenu n'ont aucune valeur juridique. Sa nature est purement informative.</p>
      </div>
      <div page-break='true' />
    </>
  );

  const ElementsFinanciersText = (
    <div className='pdf-text-container pdf-vertical-margin'>
      <p>
        La rentabilité retraitée (EBE retraité) exprime la rentabilité réelle de l'affaire après analyse de la situation
        individuelle de l'exploitant, puis retraitement de postes ciblés, afin de neutraliser certains choix de gestion
        qui lui sont propres, sans toutefois impacter la bonne marche de l'exploitation.
      </p>
    </div>
  );

  const OneYearMessage = (
    <div className='pdf-text-container'>
      <p>
        Vous n'avez renseigné qu'une seule année dans votre parcours d'évaluation. De ce fait l'affichage du graphique
        d'évolution n'est pas possible.
      </p>
      <p>
        Lors de votre prochaine estimation, nous vous invitons à renseigner au moins 2 années, afin d'obtenir
        l'affichage des graphiques d'évolution, ainsi qu'un résultat d'estimation consolidé.
      </p>
    </div>
  );

  return (
    <PdfTdcFdcContext.Provider
      value={{
        formatDataForActiviteChart,
        formatDataForVolumeAffairesChart,
        formatDataForFinancialTable,
        getBesoinDiplomeDescription,
        formatDataForResultatNetChart,
        formatDataForDoughnutChart,
        formatDataForActiviteTable,
        formatDataForRentabiliteChart,
        formatDataForPotentielCroissance,
        formatDataForMargeBruteChart,
        formatDataForRhTable,
        Introduction,
        ElementsFinanciersText,
        OneYearMessage,
      }}>
      {children}
    </PdfTdcFdcContext.Provider>
  );
}
