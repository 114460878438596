import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AccountCreditSimulators.css";
import { useAuth } from "../../../../../context/AuthContext";
import { useWindowSize } from "@uidotdev/usehooks";
import { archiveCreditSimulatorApi, fetchAllCreditSimulatorsApi } from "../../../../../api/CreditSimulatorApi";
import AppContext from "../../../../../context/AppContext";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../components/atoms/icons/general/add/Add";
import Searchbar from "../../../../../components/molecules/searchbar/Searchbar";
import Pen from "../../../../../components/atoms/icons/account/pen/Pen";
import Duplicate from "../../../../../components/atoms/icons/account/duplicate/Duplicate";
import Unarchive from "../../../../../components/atoms/icons/account/unarchive/Unarchive";
import Archive from "../../../../../components/atoms/icons/account/archive/Archive";
import { normalizeString } from "../../../../../utils/Utils";
import DuplicateCreditSimulatorModal from "../../../../../components/forms/modals/account/duplicateCreditSimulatorModal/DuplicateCreditSimulator";

function AccountCreditSimulators() {
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [creditSimulators, setCreditSimulators] = useState([]);
  const [visibleCreditSimulators, setVisibleCreditSimulators] = useState([]);
  const [duplicateCreditSimulatorInput, setDuplicateCreditSimulatorInput] = useState({
    value: "",
    error: "",
  });
  const [resetSearchValueTrigger, setResetSearchValueTrigger] = useState(false);
  const { createNotification, setModalContent, setModalVisible } = useContext(AppContext);
  const { getUuid } = useAuth();
  const navigate = useNavigate();

  const { width } = useWindowSize();

  useEffect(() => {
    fetchAllCreditSimulators();
  }, []);

  useEffect(() => {
    setResetSearchValueTrigger(!resetSearchValueTrigger);
  }, [activeTab]);

  useEffect(() => {
    if (creditSimulators?.length) setVisibleCreditSimulators(getFilteredCreditSimulators(creditSimulators));
  }, [activeTab, searchValue]);

  async function fetchAllCreditSimulators() {
    try {
      const res = (await fetchAllCreditSimulatorsApi(getUuid())).data;

      const creditSimulators = [];
      creditSimulators.push(...res);

      setCreditSimulators(creditSimulators);
      setVisibleCreditSimulators(getFilteredCreditSimulators(creditSimulators));
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de vos tableaux d'amortissement, veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function getFilteredCreditSimulators(simulators) {
    if (searchValue === "") return simulators.filter(simulator => simulator.archived === !!activeTab);

    return simulators.filter(
      simulator =>
        simulator.archived === !!activeTab &&
        normalizeString(simulator.simulator_name).includes(normalizeString(searchValue)),
    );
  }

  function showDuplicateCreditSimulatorModal(simulatorId, simulatorName) {
    setModalContent({
      title: "Dupliquer le tableau d'amortissement",
      content: (
        <DuplicateCreditSimulatorModal
          defaultValue={getDuplicateCreditSimulatorName(simulatorName)}
          fetchCreditSimulatorsFunction={() => fetchAllCreditSimulators()}
          creditSimulatorId={simulatorId}
        />
      ),
    });
    setModalVisible(true);
  }

  async function archiveCreditSimulator(simulatorId, shouldArchive) {
    try {
      await archiveCreditSimulatorApi(simulatorId, shouldArchive);
      await fetchAllCreditSimulators();
      createNotification(
        <>Votre tableau d'amortissement a été {shouldArchive ? "archivé" : "désarchivé"} avec succès</>,
      );
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors {shouldArchive ? "de l'archivage" : "du désarchivage"} de votre simulateur de
          crédit. Veuillez réessayer
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function getDuplicateCreditSimulatorName(simulatorName) {
    const regex = /#\d+$/;

    if (regex.test(simulatorName)) {
      const match = simulatorName.match(/\d+$/);
      return simulatorName.replace(regex, `#${parseInt(match[0]) + 1}`);
    } else {
      return `${simulatorName} #1`;
    }
  }

  function getCreditSimulatorUrl(simulator) {
    return `/simulateur-credit/${simulator?.credit_id}`;
  }

  return (
    <>
      <section className='container'>
        <FilledButton className='mid-page-button credit-simulators-button' to='/simulateur-credit'>
          <Add width={width > 576 ? "30px" : "20px"} /> Créer un tableau d'amortissement
        </FilledButton>
        <div className='credit-simulators-container'>
          <div className='credit-simulators-container-header'>
            <p className={activeTab === 0 ? "active" : ""} onClick={() => setActiveTab(0)}>
              Mes tableaux d'amortissement
            </p>
            <p className={activeTab === 1 ? "active" : ""} onClick={() => setActiveTab(1)}>
              Mes tableaux d'amortissement archivés
            </p>
          </div>
          <div className='search-input-container'>
            <Searchbar
              resetValueTrigger={resetSearchValueTrigger}
              onChange={value => setSearchValue(value)}
              placeholder='Rechercher'
              bgColor={width > 1200 ? "var(--white)" : "var(--beige)"}
              className='credit-simulators-search-bar'
            />
          </div>
          <div className='credit-simulators-table'>
            <div className='credit-simulators-filters'>
              <div>Nom du tableau d'amortissement</div>
              <div>Date de création</div>
              <div className='credit-simulators-filters-buttons'>
                <div className='centered' custom-title='Modifier'>
                  <Pen width='23px' />
                </div>
                <div className='centered' custom-title='Dupliquer'>
                  <Duplicate width='25px' />
                </div>
                <div className='centered' custom-title={activeTab ? "Désarchiver" : "Archiver"}>
                  {activeTab ? <Unarchive width='25px' /> : <Archive width='25px' />}
                </div>
              </div>
            </div>
            <div className='credit-simulators-table-body'>
              {visibleCreditSimulators.map(simulator => (
                <div key={simulator?.credit_id} className='credit-simulators-overview-container'>
                  <div>{simulator?.credit_name}</div>
                  <div>{simulator?.date_creation.split(" ")[0]}</div>
                  <div className='credit-simulators-filters-buttons'>
                    <FilledButton bgColor='#E6CC83' hoverColor='#E6CC83' to={getCreditSimulatorUrl(simulator)}>
                      Accéder
                    </FilledButton>
                    <FilledButton
                      bgColor='#E3E3E3'
                      hoverColor='#E3E3E3'
                      onClick={() => showDuplicateCreditSimulatorModal(simulator?.credit_id, simulator?.credit_name)}>
                      Dupliquer
                    </FilledButton>
                    {simulator.archived ? (
                      <FilledButton
                        bgColor='#FDB7A9'
                        hoverColor='#FDB7A9'
                        onClick={() => archiveCreditSimulator(simulator?.credit_id, false)}>
                        Désarchiver
                      </FilledButton>
                    ) : (
                      <FilledButton
                        bgColor='#FDB7A9'
                        hoverColor='#FDB7A9'
                        onClick={() => archiveCreditSimulator(simulator.credit_id, true)}>
                        Archiver
                      </FilledButton>
                    )}
                  </div>
                </div>
              ))}
              {!visibleCreditSimulators.length && (
                <p className='text-lg outfit-semibold my-md text-center'>
                  Aucun résultat ne correspond à votre recherche
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AccountCreditSimulators;
