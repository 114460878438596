import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../molecules/formComponents/input/Input.js";
import "./DefinitionAffaire.css";
import { ParcoursContext } from "../../../../../context/ParcoursContext.js";
import { formatDate, getFormattedDate, normalizeString, removeSpaces } from "../../../../../utils/Utils.js";
import FileInputPreview, {
  getFileInputValue,
} from "../../../../molecules/formComponents/fileInputPreview/FileInputPreview.js";
import { deleteEstimationImageApi, updateEstimationImagesApi } from "../../../../../api/EstimationApi.js";
import AppContext from "../../../../../context/AppContext.js";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput.js";

function DefinitionAffaire({ id, index }) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    unregister,
    formState: { errors },
  } = useForm();
  const inputForm = { register, setValue };
  const {
    moveToNextStep,
    step,
    activites,
    setStep,
    getStepToGo,
    setGoToStep,
    submitFormsFdc,
    postValues,
    estimationValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);
  const [activitesDropdownVisible, setActivitesDropdownVisible] = useState(false);
  const [displayedActivites, setDisplayedActivites] = useState(activites);
  const [images, setImages] = useState({ image_1: null, image_2: null, image_3: null });
  const { createNotification } = useContext(AppContext);
  const watchActivite = watch("infos.activite");

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(["infos"], setValue, estimationValues, ["activite", "date_bilan"]);

    if (estimationValues?.infos?.date_bilan)
      setValue("infos.date_bilan", formatDate(estimationValues?.infos?.date_bilan));
    else setValue("infos.date_bilan", formatDate("31/12/2023"));
    setValue("infos.activite", estimationValues?.infos?.activite?.description);

    setImages({
      image_1: estimationValues?.images?.image_1,
      image_2: estimationValues?.images?.image_2,
      image_3: estimationValues?.images?.image_3,
    });
  }, [estimationValues]);

  useEffect(() => {
    if (!watchActivite) return setDisplayedActivites(activites);

    setDisplayedActivites(
      activites.filter(element => normalizeString(element.description).includes(normalizeString(watchActivite))),
    );
  }, [activites, watchActivite]);

  async function postImages(estimationId, images) {
    try {
      const payload = {
        image_1: getFileInputValue(images.image_1),
        image_2: getFileInputValue(images.image_2),
        image_3: getFileInputValue(images.image_3),
      };

      const res = (await updateEstimationImagesApi(estimationId, payload)).data;

      setImages({
        image_1: res.image_1_path,
        image_2: res.image_2_path,
        image_3: res.image_3_path,
      });

      createNotification(<>Les images ont été sauvegardées avec succès</>);
    } catch (e) {
      createNotification(
        <>Une erreur est survenue lors de l'envoi de vos images. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function onImageClear(e, name) {
    try {
      if (!estimationValues.id) return;
      await deleteEstimationImageApi(estimationValues.id, name.split(".")[1]);
    } catch (e) {}
  }

  async function formSubmit(values) {
    try {
      let activityId;

      if (activites.length) {
        activityId = activites.find(element => element.description === values.infos.activite)?.id;
        if (!activityId) {
          setError("infos.activite", { message: "Veuillez choisir une activité dans la liste" }, { shouldFocus: true });
          document.getElementById("activite").focus();
          return;
        }
      }

      if (values.images.image_1 !== "" || values.images.image_2 !== "" || values.images.image_3 !== "")
        createNotification(<>Envoi des images en cours, cela peut prendre quelques instants.</>);

      values.infos.capital_social = parseInt(removeSpaces(values.infos.capital_social), 10);

      values.infos.activite = { id: activityId };
      values.infos.date_bilan = getFormattedDate(values.infos.date_bilan);

      const images = values.images;
      delete values.images;

      const estimationId = await postValues(values);

      await postImages(estimationId, images);

      if (getStepToGo() != 0) {
        setStep(getStepToGo());
        setGoToStep(0);
      } else moveToNextStep(index);
    } catch (e) {}
  }

  function setChosenActivite(activite) {
    setValue("infos.activite", activite.description);
    clearErrors("infos.activite");

    setTimeout(() => {
      setActivitesDropdownVisible(false);
    }, 200);
  }

  function closeDropwdown() {
    setTimeout(() => {
      setActivitesDropdownVisible(false);
    }, 200);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
      <fieldset className='borderless-fieldset'>
        <h2>définissons l’affaire à évaluer</h2>
        <Input
          useForm={inputForm}
          placeholder='Cliquez et déroulez, ou cherchez par mot-clé...'
          label='activité (vous ne pourrez pas la changer par la suite)'
          name='infos.activite'
          disabled={estimationValues?.infos?.activite?.description ? true : false}
          error={errors?.activite?.message}
          icon='search'
          onFocus={() => setActivitesDropdownVisible(true)}
          onBlur={closeDropwdown}
          preventAutoComplete
          validation={{}}
          bgColor='var(--gold)'>
          <ul className={"activites-list" + (activitesDropdownVisible ? "" : " h-none")}>
            {displayedActivites.map((activite, key) => {
              return (
                <li key={key} onClick={() => setChosenActivite(activite)}>
                  {activite.description}
                </li>
              );
            })}
            {displayedActivites.length === 0 && <li className='activites-no-result'>Aucun résultat</li>}
          </ul>
        </Input>
        <Input
          useForm={inputForm}
          label='enseigne / nom de l’affaire à évaluer'
          error={errors?.infos?.enseigne?.message}
          name='infos.enseigne'
        />
        <Input useForm={inputForm} label='n°/nom de rue' error={errors?.infos?.rue?.message} name='infos.rue' />
        <div className='row-1000 mb-sm'>
          <Input useForm={inputForm} label='ville' error={errors?.infos?.ville?.message} name='infos.ville' />
          <Input
            useForm={inputForm}
            label='code postal'
            error={errors?.infos?.code_postal?.message}
            name='infos.code_postal'
          />
        </div>
        <div className='row-1000 mb-sm'>
          <Input
            useForm={inputForm}
            label='raison sociale'
            error={errors?.infos?.raison_sociale?.message}
            name='infos.raison_sociale'
          />
          <Input useForm={inputForm} label='siret' error={errors?.infos?.siret?.message} name='infos.siret' />
        </div>
        <NumberInput
          useForm={{ watch, register, setValue, unregister }}
          label='capital social'
          error={errors?.infos?.capital_social?.message}
          name='infos.capital_social'
          icon='euro'
        />
        <Input
          useForm={inputForm}
          name='infos.date_bilan'
          type='date'
          label='date du dernier bilan'
          bgColor='var(--pale-blue)'
          disabled={estimationValues?.infos?.date_bilan ? true : false}
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
        />
        <div className='centered mt-3'>
          <p className='text-sky-blue poppins-bold'>Enregistrez jusqu'à trois images à ajouter sur votre rapport</p>
          <p>(la première apparaîtra sur la page de garde, les suivantes sur les pages annexes)</p>
        </div>
        <div className='row-1000 gap-md mt-md space-between'>
          <FileInputPreview
            register={register}
            setValue={setValue}
            name='images.image_1'
            label='image 1'
            previewDefaultValue={images.image_1}
            onClear={onImageClear}
          />
          <FileInputPreview
            register={register}
            setValue={setValue}
            name='images.image_2'
            label='image 2'
            previewDefaultValue={images.image_2}
            onClear={onImageClear}
          />
          <FileInputPreview
            register={register}
            setValue={setValue}
            name='images.image_3'
            label='image 3'
            previewDefaultValue={images.image_3}
            onClear={onImageClear}
          />
        </div>
      </fieldset>
    </form>
  );
}

export default DefinitionAffaire;
