import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import Radio from "../../../../molecules/formComponents/radio/Radio";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput";
import {
  numberValidation,
  decimalValidation,
  max2250Validation,
  max365Validation,
} from "../../../../../utils/formValidation/FormValidation";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import Input from "../../../../molecules/formComponents/input/Input";
import "./RatioClefs.css";
import { formatObjectForPosting, resetObjectValues } from "../../../../../utils/Utils";
import Tuto from "../../../../atoms/icons/general/tuto/Tuto";
import RhMultiplesInputs, {
  fillMultipleRhInputs,
  removeSpacesFromRhMultiplesInputs,
} from "../../../../molecules/formComponents/rhMultiplesInputs/RhMultiplesInputs";

function RatioClefs({ id, index }) {
  const {
    register,
    watch,
    setValue,
    unregister,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    showTooltip,
    handleTabs,
    typesCommerce,
    moveToNextStep,
    step,
    getStepToGo,
    setGoToStep,
    setStep,
    submitFormsFdc,
    postValues,
    fillInputsFromEstimationValues,
    estimationValues,
  } = useContext(ParcoursContext);

  const inputForm = { register, unregister, setValue, watch };
  const franchiseWatcher = watch("franchise_checkbox");
  const infosComplementairesWatcher = watch("infos_complementaires_12_checkbox");
  const [rh, setRh] = useState([
    { index: 0, visible: true, error: { nom: "", poste: "", salaire: "", date_entree: "", type_contrat: "" } },
  ]);
  const detailRhWatcher = watch("detail_rh_checkbox");

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(["ratios"], setValue, estimationValues);
    fillInputsFromEstimationValues(
      ["hotellerie", "infos_complementaires"],
      setValue,
      estimationValues.ratios,
      [],
      "ratios.",
    );
    setValue("notes.note_step_12", estimationValues?.notes?.note_step_12);

    // set franchise
    if (estimationValues?.step >= index)
      if (estimationValues?.ratios?.franchise?.montant) {
        setValue("franchise_checkbox", "2");
        setValue("ratios.franchise.montant", estimationValues?.ratios?.franchise.montant);
      } else setValue("franchise_checkbox", "1");

    //set infos complentaires
    if (estimationValues?.ratios?.infos_complementaires?.date_debut_exploitation)
      setValue("infos_complementaires_12_checkbox", true);

    // set rh
    if (estimationValues?.ratios?.detail_rh?.length) {
      setValue("detail_rh_checkbox", true);
      fillMultipleRhInputs(estimationValues?.ratios?.detail_rh, setValue, setRh, "rh");
    }
  }, [estimationValues]);

  async function formSubmit(values) {
    if (!values.infos_complementaires_12_checkbox)
      values.ratios.infos_complementaires = resetObjectValues(values.ratios.infos_complementaires);

    //set franchise
    if (values.franchise_checkbox === "1") values.ratios.franchise = { montant: null };

    delete values.infos_complementaires_12_checkbox;
    delete values.franchise_checkbox;

    //set rh
    const tmp = values.detail_rh_checkbox ? removeSpacesFromRhMultiplesInputs(values.rh) : [];

    values = formatObjectForPosting(values);

    values.ratios.detail_rh = tmp;
    delete values.rh;
    delete values.detail_rh_checkbox;

    await postValues(values);

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
      <fieldset className='borderless-fieldset'>
        <h2>ratios clefs & informations complémentaires</h2>
        <div className='row-1000'>
          <NumberInput
            useForm={inputForm}
            label={
              <>
                nombre de salariés travaillant dans l’entreprise, hormis le dirigeant
                <Tuto onClick={() => showTooltip("step_12_salaries.webp")} />
              </>
            }
            error={errors?.ratios?.salaries?.message}
            name='ratios.salaries'
          />
          <NumberInput
            useForm={inputForm}
            className={typesCommerce.includes(4) ? "" : "w-0"}
            visible={typesCommerce.includes(4)}
            label={
              <>
                pourcentage de liquides (ou limonade) par rapport aux solides
                <Tuto onClick={() => showTooltip("step_12_pourcentage_liquides_bar.webp")} />
              </>
            }
            error={errors?.ratios?.pourcentage_liquides_bar?.message}
            name='ratios.pourcentage_liquides_bar'
            icon='percent'
            validation={decimalValidation}
          />
          <NumberInput
            useForm={inputForm}
            label={
              <>
                potentiel de développement du chiffre d’affaires à 3 ans
                <Tuto onClick={() => showTooltip("step_12_potentiel_ca_3_ans.webp")} />
              </>
            }
            error={errors?.ratios?.potentiel_ca_3_ans?.message}
            name='ratios.potentiel_ca_3_ans'
            icon='percent'
            validation={decimalValidation}
          />
        </div>
        <Checkbox label='je souhaite détailler les ressources humaines' name='detail_rh_checkbox' useForm={inputForm} />
        <RhMultiplesInputs
          Form={inputForm}
          unregister={unregister}
          values={rh}
          setValues={setRh}
          name='rh'
          visible={detailRhWatcher}
        />
        <Radio
          label="anciennete d’exploitation de l'affaire"
          useForm={inputForm}
          error={errors?.ratios?.anciennete_exploitation_affaire?.id?.message}
          name='ratios.anciennete_exploitation_affaire.id'
          content={[
            {
              value: 1,
              label: "< 1 an",
            },
            {
              value: 2,
              label: "1 à 2 ans",
            },
            {
              value: 3,
              label: "2 à 3 ans",
            },
            {
              value: 4,
              label: "3 à 4 ans",
            },
            {
              value: 5,
              label: "4 à 7 ans",
            },
            {
              value: 6,
              label: "> 7 ans",
            },
          ]}
        />
        <div className='row-1000'>
          <NumberInput
            visible={typesCommerce.includes(8)} //Hôtels
            useForm={inputForm}
            icon='percent'
            label="Taux d'occupation moyen"
            error={errors?.ratios?.hotellerie?.taux_occupation_moyen?.message}
            name='ratios.hotellerie.taux_occupation_moyen'
            validation={decimalValidation}
          />
          <NumberInput
            visible={typesCommerce.includes(8)} //Hôtels
            useForm={inputForm}
            icon='euro'
            label='Prix moyen chambre HT'
            error={errors?.ratios?.hotellerie?.prix_moyen_chambre_ht?.message}
            name='ratios.hotellerie.prix_moyen_chambre_ht'
            validation={decimalValidation}
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            visible={typesCommerce.includes(9)} //CHR sauf hôtels
            useForm={inputForm}
            icon='euro'
            label='Ticket moyen par client (ht)'
            error={errors?.ratios?.hotellerie?.ticket_moyen_client_ht?.message}
            name='ratios.hotellerie.ticket_moyen_client_ht'
            validation={decimalValidation}
          />
          <NumberInput
            visible={typesCommerce.includes(9)} //CHR sauf hôtels
            useForm={inputForm}
            label='Nombre moyen de couverts / jour'
            error={errors?.ratios?.hotellerie?.couverts_jour?.message}
            name='ratios.hotellerie.couverts_jour'
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            visible={typesCommerce.includes(9)} //CHR sauf hôtels
            useForm={inputForm}
            label='nombre de kg de café / semaine'
            error={errors?.ratios?.hotellerie?.kg_cafe_semaine?.message}
            name='ratios.hotellerie.kg_cafe_semaine'
            validation={decimalValidation}
          />
          <NumberInput
            visible={typesCommerce.includes(9)} //CHR sauf hôtels
            useForm={inputForm}
            label="nombre moyen d'hectolitres de bière / an"
            error={errors?.ratios?.hotellerie?.hectolitres_biere_an?.message}
            name='ratios.hotellerie.hectolitres_biere_an'
            validation={decimalValidation}
          />
        </div>
        <div className='row-1000'>
          <NumberInput
            useForm={inputForm}
            label={
              <>
                nombre de jours d’ouverture dans l’année
                <Tuto onClick={() => showTooltip("step_12_jours_ouverture.webp")} />
              </>
            }
            error={errors?.ratios?.jours_ouverture?.message}
            validation={max365Validation}
            name='ratios.jours_ouverture'
          />
          <Radio
            label={
              <>
                50% ou plus du ca sont ils réalisés par 1 à 3 clients ?
                <Tuto onClick={() => showTooltip("step_12_client_dependance.webp")} />
              </>
            }
            useForm={inputForm}
            error={errors?.ratios?.client_dependance?.id?.message}
            name='ratios.client_dependance.id'
            content={[
              {
                value: 1,
                label: "Non",
              },
              {
                value: 2,
                label: "Oui",
              },
            ]}
          />
        </div>
        <div className='row-1000'>
          <Radio
            label={<>l'exploitant est-il franchisé / adhérent / concessionnaire / licencié d’une enseigne&nbsp;?</>}
            useForm={inputForm}
            error={errors?.franchise_checkbox?.message}
            name='franchise_checkbox'
            content={[
              {
                value: 1,
                label: "Non",
              },
              {
                value: 2,
                label: "Oui",
              },
            ]}
          />
          <div className='w-100' />
        </div>
        <div className='row-1000'>
          <NumberInput
            useForm={inputForm}
            visible={franchiseWatcher == 2}
            label="montant de la franchise (droit d'entrée)"
            error={errors?.ratios?.franchise?.montant?.message}
            name='ratios.franchise.montant'
            icon='euro'
          />
          <div className='w-100' />
        </div>
        <Checkbox
          label="je souhaite renseigner des informations complémentaires sur le fonctionnement de l'affaire"
          name='infos_complementaires_12_checkbox'
          useForm={inputForm}
        />
        <div className='row-1000'>
          <Input
            visible={infosComplementairesWatcher}
            label="Le vendeur exploite l'affaire depuis le"
            type='date'
            name='ratios.infos_complementaires.date_debut_exploitation'
            error={errors?.ratios?.infos_complementaires?.date_debut_exploitation?.message}
            useForm={inputForm}
          />
          <Input
            visible={infosComplementairesWatcher}
            label='Amplitude horaire'
            name='ratios.infos_complementaires.amplitude_horaire'
            error={errors?.ratios?.infos_complementaires?.amplitude_horaire?.message}
            useForm={inputForm}
          />
        </div>
        <div className='row-1000'>
          <Input
            visible={infosComplementairesWatcher}
            label='Fermeture hebdomadaire'
            name='ratios.infos_complementaires.fermeture_hebdomadaire'
            error={errors?.ratios?.infos_complementaires?.fermeture_hebdomadaire?.message}
            useForm={inputForm}
          />
          <Input
            visible={infosComplementairesWatcher}
            label='Congés annuels'
            name='ratios.infos_complementaires.conges_annuels'
            error={errors?.ratios?.infos_complementaires?.conges_annuels?.message}
            useForm={inputForm}
          />
        </div>
        <Textarea
          className='small-textarea'
          visible={infosComplementairesWatcher}
          useForm={inputForm}
          name='ratios.infos_complementaires.motif'
          error={errors?.ratios?.infos_complementaires?.motif?.message}
          label='motif de la vente'
        />
        <Textarea
          useForm={inputForm}
          name='notes.note_step_12'
          error={errors?.notes?.note_step_12?.message}
          label='notes et remarques'
          className='mt-md'
          {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
        />
      </fieldset>
    </form>
  );
}

export default RatioClefs;
