import "./FinancingPlanForm.css";
import React, { useEffect, useState } from "react";
import Toggle from "../../../components/molecules/formComponents/toggle/Toggle";
import FinancingPlanFdc from "./financingPlanFdc/FinancingPlanFdc";
import FinancingPlanMurs from "./financingPlanMurs/FinancingPlanMurs";
import { formatNumberWithSpaces, formatObjectForPosting, removeSpaces } from "../../../utils/Utils";
import UnfilledButton from "../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import { FinancingPlanContext } from "./financingPlanContext/FinancingPlanContext";
import { useContext } from "react";
import AppContext from "../../../context/AppContext";
import { useForm } from "react-hook-form";
import Input from "../../molecules/formComponents/input/Input";
import { useNavigate, useParams } from "react-router-dom";
import { formatMultiplesInputsValues } from "../../molecules/formComponents/multiplesInputs/MultiplesInputs";
import { createFinancingPlanApi, fetchFinancingPlanApi, updateFinancingPlanApi } from "../../../api/FinancingPlanApi";
import { useAuth } from "../../../context/AuthContext";

function FinancingPlanForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    unregister,
    watch,
    getValues,
  } = useForm();
  const inputForm = { register, setValue, unregister, watch };
  const [multipleInputsDefaultValues, setMultipleInputsDefaultValues] = useState({
    fdc: {
      postes: [],
      apports: [],
    },
    murs: { postes: [] },
  });
  const { planTypeParam, planIdParam } = useParams();
  const { getUuid } = useAuth();

  const { createNotification, setAppLoaderVisible } = useContext(AppContext);
  const navigate = useNavigate();

  const defaultValues = {
    taux_emprunt: 4.5,
    ratio_frais_notaire: 7.5,
    fonds_roulement: 10000,
  };

  useEffect(() => {
    switch (planTypeParam) {
      case "fdc":
        setValue("plan_type_id", 0);
        break;
      case "murs":
        setValue("plan_type_id", 1);
        break;
      default:
        navigate("/404");
    }
  }, [planTypeParam]);

  useEffect(() => {
    if (planIdParam) {
      setAppLoaderVisible(true);
      fetchFinancingPlan(planIdParam);
    }
  }, [planIdParam]);

  async function fetchFinancingPlan(planId) {
    try {
      const res = (await fetchFinancingPlanApi(planId)).data.financing_plan;

      setValue("financing_plan_name", res.financing_plan_name);
      if (res.financing_plan_type === "murs") fillMursValues(res);
      else fillFdcValues(res);
      setAppLoaderVisible(false);
    } catch (error) {
      setAppLoaderVisible(false);
      createNotification(
        <>
          Une erreur est survenue lors de la récupération de votre plan de financement, veuillez réessayer
          <br />
          Code d'erreur : {error.response.status}
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function formSubmit(values) {
    try {
      const postValues = planTypeParam === "murs" ? formatMursValues(values) : formatFdcValues(values);

      if (!getUuid()) {
        localStorage.setItem("financingPlan", JSON.stringify(postValues));
        localStorage.setItem("financingPlanType", JSON.stringify(planTypeParam));

        createNotification(<>Vous devez être connecté pour sauvegarder un plan de financement.</>);
        return navigate("/connexion?redirect=/plan-financement", { replace: true });
      }

      if (planIdParam) {
        const res = (await updateFinancingPlanApi(postValues, planIdParam)).data.financing_plan;
        fillMursValues(res);
      } else {
        const res = (await createFinancingPlanApi(postValues, planTypeParam)).data.financing_plan;
        navigate(`/plan-financement/${planTypeParam}/${res.financing_plan_id}`, { replace: true });
        fillMursValues(res);
      }

      window.scrollTo(0, 0);

      createNotification(<>Votre plan de financement a été sauvegardé avec succès</>);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors de la sauvegarde de votre plan de financement, veuillez réessayer
          <br />
          Code d'erreur : {error.response.status}
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function formatFdcValues(values) {
    values = formatObjectForPosting(values);
    const postes = formatMultiplesInputsValues(values.fdc.postes);
    const apports = formatMultiplesInputsValues(values.fdc.apports);
    delete values.fdc.postes;
    delete values.fdc.apport;

    for (const key in values.fdc) {
      if (!values.fdc[key]) values.fdc[key] = 0;
    }

    return {
      financing_plan_name: values.financing_plan_name,
      ...values.fdc,
      inputs: { postes: postes, apports: apports },
    };
  }

  function formatMursValues(values) {
    values = formatObjectForPosting(values);
    const postes = formatMultiplesInputsValues(values.murs.postes);
    delete values.murs.postes;

    for (const key in values.murs) {
      if (!values.murs[key]) values.murs[key] = 0;
    }

    return {
      financing_plan_name: values.financing_plan_name,
      ...values.murs,
      inputs: { postes: postes },
    };
  }

  function fillMursValues(values) {
    setMultipleInputsDefaultValues(prev => ({ ...prev, murs: values.inputs }));

    delete values.financing_plan_id;
    delete values.financing_plan_name;
    delete values.user_id;
    Object.keys(values).forEach(key => setValue(`murs.${key}`, values[key]));
  }

  function fillFdcValues(values) {
    setMultipleInputsDefaultValues(prev => ({ ...prev, fdc: values.inputs }));

    delete values.financing_plan_id;
    delete values.financing_plan_name;
    delete values.user_id;

    Object.keys(values).forEach(key => setValue(`fdc.${key}`, values[key]));
  }

  function getValidation(visible) {
    return {
      required: {
        value: visible,
        message: "Ce champ est obligatoire",
      },
    };
  }

  function switchPlanType(e) {
    navigate(`/plan-financement/${e.target.checked ? "murs" : "fdc"}`, { replace: true });
  }

  function getFormValues(names) {
    const result = {};

    names.map(name => {
      result[name] = removeSpaces(getValues(`${planTypeParam}.${name}`));
    });

    return result;
  }

  function setFormValue(name, value, isPercentage) {
    if (isPercentage) value = Math.round(value * 10) / 10;
    else value = Math.round(value);

    if (value != getValues(`${planTypeParam}.${name}`))
      setValue(`${planTypeParam}.${name}`, formatNumberWithSpaces(value, true));
  }

  async function savePlan(redirect) {
    handleSubmit(async data => {
      await formSubmit(data);

      if (redirect) navigate("/mon-compte/mes-outils/mes-plans-de-financement");
    })();
  }

  return (
    <FinancingPlanContext.Provider
      value={{
        register,
        getFormValues,
        setFormValue,
        getValues,
        setValue,
        errors,
        getValidation,
        inputForm,
        planIdParam,
        watch,
        defaultValues,
        multipleInputsDefaultValues,
      }}>
      <div className='financing-plan-page-container'>
        <form onSubmit={() => savePlan}>
          <fieldset className='borderless-fieldset full-page-form'>
            <div className='centered'>
              <p className='form-subtitle my-sm'>
                Note : certains montants calculés automatiquement sont modifiables manuellement
              </p>
              {!planIdParam && (
                <Toggle
                  useForm={inputForm}
                  bgColor='var(--sky-blue)'
                  label1='Murs commerciaux'
                  label='Fonds de commerce'
                  name='plan_type_id'
                  onChange={switchPlanType}
                />
              )}
            </div>
            <Input
              useForm={inputForm}
              label='Intitulé du plan de financement'
              bgColor='var(--pale-blue)'
              name='financing_plan_name'
              error={errors?.financing_plan_name?.message}
            />
          </fieldset>
          <div className='financing-plan-container'>
            <FinancingPlanMurs visible={watch("plan_type_id")} />
            <FinancingPlanFdc visible={!watch("plan_type_id")} />
          </div>
        </form>
        <div className='financing-plan-buttons'>
          <div className='financing-plan-save-dowload-buttons'>
            <UnfilledButton onClick={() => savePlan()} padding='10px 25px'>
              Enregistrer
            </UnfilledButton>
            <FilledButton onClick={() => savePlan(true)} padding='10px 25px' disabled>
              Télécharger
            </FilledButton>
          </div>
        </div>
      </div>
    </FinancingPlanContext.Provider>
  );
}

export default FinancingPlanForm;
