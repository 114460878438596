import { PdfContext } from "../../../context/PdfContext";
import { formatNumberWithSpaces } from "../../../utils/Utils";
import "./CoverPdf.css";
import React, { useContext } from "react";

// estimationType = 1: fdc, 2: tds, 3: mc
function CoverPdf() {
  const { config, mainYear, estimationType, businessImages } = useContext(PdfContext);

  return (
    <div className={`cover-pdf-container ${estimationType === 2 ? "type-tds" : ""}`}>
      <img src={config.logos.cover} alt='logo' className='cover-pdf-logo' />
      <h1>
        {estimationType === 1 ? "Fonds de commerce" : estimationType === 2 ? "Titres de société" : "Murs commerciaux"} -{" "}
        <span>Rapport d'estimation</span>
      </h1>
      <div className='pdf-cover-infos-container'>
        <div className='pdf-cover-name'>{mainYear?.infos.enseigne}</div>
        <div>{mainYear?.infos.raison_sociale}</div>
        {estimationType !== 3 && (
          <>
            <div>Capital social : {formatNumberWithSpaces(mainYear?.infos.capital_social, true, "€")}</div>
            <div>{mainYear?.infos.activite.description}</div>
            <div>Siret : {mainYear?.infos.siret}</div>
          </>
        )}
        <div className='pdf-cover-address'>
          {mainYear?.infos?.rue} {estimationType === 3 ? <br /> : null} {mainYear?.infos?.code_postal}{" "}
          {mainYear?.infos?.ville}
        </div>
      </div>
      {businessImages.length ? (
        <img className='pdf-business-image' src={businessImages[0]} alt="Image de l'affaire" />
      ) : null}
    </div>
  );
}

export default CoverPdf;
