import React, { useContext, useState } from "react";
import Input from "../../../molecules/formComponents/input/Input";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import { emailValidation, passwordValidation } from "../../../../utils/formValidation/FormValidation";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./LoginForm.css";
import { askReactivationEmailApi, loginUserApi } from "../../../../api/AccountApi";
import AppContext from "../../../../context/AppContext";
import ForgotPasswordModal from "../../modals/account/forgotPasswordModal/ForgotPasswordModal";
import PasswordInput from "../../../molecules/formComponents/passwordInput/PasswordInput";
import RegisterConfirmModal from "../../modals/account/registerConfirmModal/RegisterConfirmModal";
import { useAuth } from "../../../../context/AuthContext";
import UnfilledButton from "../../../molecules/buttons/unfilledButton/UnfilledButton";
import { createFinancingPlanApi } from "../../../../api/FinancingPlanApi";

function LoginForm() {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const inputForm = { register, setValue };
  const { createNotification, setModalContent, setModalVisible } = useContext(AppContext);
  const { setUuid, getUuid } = useAuth();
  const [formError, setFormError] = useState(<></>);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  async function onSubmit(values) {
    try {
      const res = await loginUserApi({
        email: values.email,
        password: values.password,
      });
      setUuid(res.data.uuid);
      setFormError(<></>);

      createFinancingPlan();

      if (searchParams.get("redirect")) navigate(searchParams.get("redirect"));
      navigate("/mon-compte/mes-outils");
    } catch (error) {
      let notificationMessage = <></>;

      switch (error.response.status) {
        case 401:
          notificationMessage = <>Votre email ou votre mot de passe est incorrect.</>;
          break;
        case 403:
          switch (error.response.data.infos.error_code) {
            case "01":
              localStorage.setItem("temporary_uuid", error.response.data.infos.uuid);
              showConfirmInscriptionModal();
              return;
            case "02":
              showDesactivedAccountModal(values.email);
              return;
          }
        case 500:
        default:
          notificationMessage = <>Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.</>;
      }

      setFormError(notificationMessage);
      createNotification(notificationMessage, "var(--red)", "var(--dark-blue");
    }
  }

  async function createFinancingPlan() {
    try {
      const financingPlan = JSON.parse(localStorage.getItem("financingPlan"));
      const financingPlanType = JSON.parse(localStorage.getItem("financingPlanType"));

      await createFinancingPlanApi(financingPlan, financingPlanType);

      localStorage.removeItem("financingPlan");
      localStorage.removeItem("financingPlanType");

      createNotification(
        <>
          Le plan de financement que vous avez créé précédemment a été sauvegardé avec succès.{" "}
          <a href='/mon-compte/mes-plans-de-financement'>Vous pouvez le retrouver ici.</a>
        </>,
      );
    } catch (error) {}
  }

  function showDesactivedAccountModal(email) {
    setModalContent({
      title: "Compte désactivé",
      content: (
        <>
          Votre compte est désactivé.
          <br />
          <br />
          Cliquez sur le bouton ci-dessous pour recevoir un email vous permettant de le réactiver.
          <div className='modal-buttons-row'>
            <UnfilledButton onClick={() => setModalVisible()}>Annuler</UnfilledButton>
            <FilledButton onClick={() => askReactivationEmail(email)}>Envoyer l'email</FilledButton>
          </div>
        </>
      ),
    });

    setModalVisible(true);
  }

  async function askReactivationEmail(email) {
    try {
      await askReactivationEmailApi(email);
      createNotification(<>Un email de réactivation de votre compte a été envoyé à l'adresse {email} avec succès.</>);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors de l'envoi de l'email de réactivation de votre compte. Veuillez réessayer plus
          tard
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
    }
  }

  function showConfirmInscriptionModal() {
    setModalContent({
      title: "Confirmer votre inscription",
      content: (
        <>
          <p className='centered'>Votre compte n'est pas encore activé</p>
          <RegisterConfirmModal
            email={getValues("email")}
            password={getValues("password")}
            navigate={navigate}
            setUuid={setUuid}
            getUuid={getUuid}
          />
        </>
      ),
    });
    setModalVisible(true);
  }

  function showForgotPasswordModal() {
    setModalContent({ title: "Mot de passe oublié", content: <ForgotPasswordModal email={getValues("email")} /> });
    setModalVisible(true);
    setFormError(<></>);
    setValue("email", "");
    setError("email", { type: "manual", message: "" });
    setValue("password", "");
    setError("password", { type: "manual", message: "" });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className='borderless-fieldset'>
        <h2>Se connecter</h2>
        <Input
          type='email'
          useForm={inputForm}
          error={errors?.email?.message}
          validation={emailValidation}
          name='email'
          label='adresse mail'
        />
        <PasswordInput
          type='password'
          useForm={inputForm}
          error={errors?.password?.message}
          validation={passwordValidation}
          name='password'
          label='mot de passe'
        />
        <div className='form-error-container'>{formError}</div>
        <div className='centered mt-sm'>
          <FilledButton type='submit' padding='10px 25px'>
            Se connecter
          </FilledButton>
        </div>
        <Link className='forgot-password' onClick={showForgotPasswordModal}>
          Mot de passe oublié ?
        </Link>
      </fieldset>
    </form>
  );
}

export default LoginForm;
