import React, { useContext, useState, useEffect } from "react";
import "./AddFinancingPlanToDeliverableModal.css";
import AppContext from "../../../../../context/AppContext";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import { addFinancingPlanToDeliverableApi, fetchDeliverableByIdApi } from "../../../../../api/FolderApi";

function AddFinancingPlanToDeliverableModal({ folder, deliverableId, onFinancingPlansAdded = () => {} }) {
  const { setModalVisible, createNotification } = useContext(AppContext);
  const [selectedFinancingPlans, setSelectedFinancingPlans] = useState([]);
  const [folderFinancingPlans, setFolderFinancingPlans] = useState([]);
  const [existingFinancingPlans, setExistingFinancingPlans] = useState([]);

  useEffect(() => {
    if (folder?.financing_plans) {
      let financingPlansArray = [];

      if (Array.isArray(folder.financing_plans)) {
        financingPlansArray = folder.financing_plans;
      } else {
        financingPlansArray = [
          ...(folder.financing_plans.financing_plan_fdc || []).map(planWrapper => planWrapper.financing_plan),
          ...(folder.financing_plans.financing_plan_murs || []).map(planWrapper => planWrapper.financing_plan),
        ];
      }

      setFolderFinancingPlans(financingPlansArray);
    } else {
      setFolderFinancingPlans([]);
    }
  }, [folder]);

  useEffect(() => {
    async function fetchExistingFinancingPlans() {
      try {
        const res = await fetchDeliverableByIdApi(deliverableId);
        const deliverableData = res.data.deliverable;

        let financingPlansInDeliverable = [];

        if (Array.isArray(deliverableData.financing_plans)) {
          financingPlansInDeliverable = deliverableData.financing_plans;
        } else {
          financingPlansInDeliverable = [
            ...(deliverableData.financing_plans.financing_plan_fdc || []).map(
              planWrapper => planWrapper.financing_plan,
            ),
            ...(deliverableData.financing_plans.financing_plan_murs || []).map(
              planWrapper => planWrapper.financing_plan,
            ),
          ];
        }

        setExistingFinancingPlans(financingPlansInDeliverable);
      } catch (error) {
        createNotification(
          <>Une erreur est survenue lors de la récupération des plans de financement. Veuillez réessayer</>,
          "var(--red)",
          "var(--dark-blue)",
        );
      }
    }

    if (deliverableId) {
      fetchExistingFinancingPlans();
    }
  }, [deliverableId]);

  function toggleFinancingPlanSelection(financingPlan) {
    if (selectedFinancingPlans.some(e => e.financing_plan_id === financingPlan.financing_plan_id)) {
      setSelectedFinancingPlans(
        selectedFinancingPlans.filter(e => e.financing_plan_id !== financingPlan.financing_plan_id),
      );
    } else {
      setSelectedFinancingPlans([...selectedFinancingPlans, financingPlan]);
    }
  }

  async function handleAddFinancingPlans() {
    try {
      for (const financingPlan of selectedFinancingPlans) {
        await addFinancingPlanToDeliverableApi(deliverableId, financingPlan.financing_plan_id);
      }
      onFinancingPlansAdded();
      createNotification(
        <>Plans(s) de financement ajouté(s) avec succès au livrable</>,
        "var(--green)",
        "var(--dark-blue)",
      );
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'ajout des plans de financement au livrable</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
    }
  }

  const financingPlansToDisplay = folderFinancingPlans.filter(
    financingPlan => !existingFinancingPlans.some(e => e.financing_plan_id === financingPlan.financing_plan_id),
  );

  return (
    <div>
      <div className='info-add-financing-plans'>
        <p>Sélectionnez les plans de financement à ajouter au livrable.</p>
      </div>
      <div className='checkbox-list-container'>
        <h3>Plan(s) de financement disponibles dans le dossier</h3>
        <div className='import-financingplans-to-deliverable'>
          {financingPlansToDisplay.length > 0 ? (
            financingPlansToDisplay.map(financingPlan => (
              <Checkbox
                key={financingPlan.financing_plan_id}
                name={`financingPlan-${financingPlan.financing_plan_id}`}
                className='checkbox-financingplans-item'
                label={financingPlan.financing_plan_name}
                onChange={() => toggleFinancingPlanSelection(financingPlan)}
                checked={selectedFinancingPlans.some(e => e.financing_plan_id === financingPlan.financing_plan_id)}
              />
            ))
          ) : (
            <p>Aucun plan de financement disponible à ajouter.</p>
          )}
        </div>
      </div>
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton
          padding='10px 25px'
          onClick={handleAddFinancingPlans}
          disabled={selectedFinancingPlans.length === 0}>
          Ajouter au livrable
        </FilledButton>
      </div>
    </div>
  );
}

export default AddFinancingPlanToDeliverableModal;
