import React, { useContext } from "react";
import Input from "../../../molecules/formComponents/input/Input";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import { useForm } from "react-hook-form";
import { defaultValidation, emailValidation, phoneValidation } from "../../../../utils/formValidation/FormValidation";
import Textarea from "../../../molecules/formComponents/textarea/Textarea";
import { demoRequestApi } from "../../../../api/UtilsApi";
import AppContext from "../../../../context/AppContext";

function DemoForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { setModalVisible, createNotification } = useContext(AppContext);

  async function onSubmit(values) {
    try {
      await demoRequestApi(values.first_name, values.last_name, values.email, values.phone, values.content);
      createNotification(<>Votre demande de démonstration a bien été envoyée</>);
      setModalVisible(false);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'envoi de votre demande de démonstration</>,
        "var(--red)",
        "var(--dark-blue)",
      );
      setModalVisible(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='row-1000'>
        <Input
          useForm={{ register: register }}
          error={errors?.last_name?.message}
          validation={defaultValidation}
          name='last_name'
          label='Nom *'
        />
        <Input
          useForm={{ register: register }}
          error={errors?.first_name?.message}
          validation={defaultValidation}
          name='first_name'
          label='Prénom *'
        />
      </div>
      <div className='row-1000'>
        <Input
          useForm={{ register: register }}
          validation={emailValidation}
          error={errors?.email?.message}
          name='email'
          label='Adresse mail *'
        />
        <Input
          useForm={{ register: register }}
          validation={phoneValidation}
          error={errors?.phone?.message}
          name='phone'
          label='Téléphone *'
        />
      </div>
      <Textarea
        useForm={{ register: register }}
        error={errors?.message?.message}
        validation={defaultValidation}
        name='content'
        label='Décrivez-nous vos besoins *'
        defaultValue={`Bonjour,\n\nJe souhaite bénéficier d’une démonstration en ligne de votre outil d’estimation.\n\nMerci de me contacter dès que possible.`}
        className=' textarea-demo-form'
      />
      <div className='form-legend'>* Obligatoire</div>
      <div className='centered mb-sm'>
        <FilledButton type='submit' padding='10px 25px'>
          Valider
        </FilledButton>
      </div>
    </form>
  );
}

export default DemoForm;
