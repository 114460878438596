import { useForm } from "react-hook-form";
import "./NewsletterForm.css";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { defaultValidation, emailValidation } from "../../../../utils/formValidation/FormValidation";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import AppContext from "../../../../context/AppContext";
import { newsletterSubscriptionApi } from "../../../../api/UtilsApi";

function NewsletterForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { createNotification } = useContext(AppContext);

  async function submitForm(values) {
    try {
      await newsletterSubscriptionApi(values.email);
      createNotification(
        <>Votre demande d'inscription à la newsletter a bien été envoyée</>,
        "var(--green)",
        "var(--dark-blue)",
      );
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'envoi de votre demande d'inscription à la newsletter</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <form onSubmit={handleSubmit(submitForm)} className='newsletter-form-container'>
      <div className='newsletter-checkbox-container'>
        <label htmlFor='subscribed'>
          En cochant cette case j'accepte de m'inscrire à la newsletter de EstimerMonCommerce.fr
        </label>
        <input id='subscribed' {...register("subscribed", defaultValidation)} type='checkbox' />
      </div>
      {errors?.subscribed && <div className='newsletter-error'>{errors.subscribed.message}</div>}
      <p>
        En indiquant votre adresse e-mail ci-dessus, vous consentez à recevoir par voie électronique la newsletter du
        site <Link to='https://estimermoncommerce.fr'>www.estimermoncommerce.fr</Link>, comprenant des informations
        concernant son actualité, ses activités et ses offres commerciales. Vous pourrez à tout moment vous désinscrire
        en utilisant le lien de désinscription intégré dans la newsletter ou en contactant GALAXICO par e-mail :{" "}
        <Link to='mailto:contact@estimermoncommerce.fr'>contact@estimermoncommerce.fr</Link>.
      </p>

      <input placeholder='Mon adresse mail :' {...register("email", emailValidation)} type='email' />
      {errors?.email && <div className='newsletter-error'>{errors.email.message}</div>}
      <div className='centered'>
        <FilledButton
          type='submit'
          className='mt-lg'
          padding='10px 20px'
          fontSize='0.9rem'
          bgColor='var(--light-gold)'
          hoverColor='var(--light-gold)'
          color='var(--dark-blue)'>
          Valider
        </FilledButton>
      </div>
    </form>
  );
}

export default NewsletterForm;
