import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import Radio from "../../../../molecules/formComponents/radio/Radio";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import NumberInput from "../../../../molecules/formComponents/numberInput/NumberInput";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea";
import { rate10Validation, rate5Validation } from "../../../../../utils/formValidation/FormValidation";
import { formatObjectForPosting } from "../../../../../utils/Utils";
import Tuto from "../../../../atoms/icons/general/tuto/Tuto";

function Reputation({ id, index }) {
  const {
    register,
    watch,
    unregister,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    showTooltip,
    handleTabs,
    typesCommerce,
    step,
    submitFormsFdc,
    postValues,
    getStepToGo,
    setGoToStep,
    fillInputsFromEstimationValues,
    estimationValues,
    setStep,
  } = useContext(ParcoursContext);
  const carac_hors_normes = watch("carac_hors_normes_checkbox");

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  const inputForm = { register, watch, setValue, unregister };

  useEffect(() => {
    fillInputsFromEstimationValues(["e_reputation", "coef_ponderation"], setValue, estimationValues);
    setValue("diplome_certification.id", estimationValues?.diplome_certification?.id + "");
    setValue("notes.note_step_13", estimationValues?.notes?.note_step_13);

    if (estimationValues?.carac_hors_normes) {
      setValue("carac_hors_normes_checkbox", true);
      setValue("carac_hors_normes", estimationValues?.carac_hors_normes);
    }
  }, [estimationValues]);

  async function formSubmit(values) {
    if (!values.carac_hors_normes_checkbox) values.carac_hors_normes = null;

    delete values.carac_hors_normes_checkbox;

    await postValues(formatObjectForPosting(values));

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    }
  }
  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
      <fieldset className='borderless-fieldset'>
        <h2 className='flex gap-sm'>
          e-réputation
          <Tuto onClick={() => showTooltip("step_13_e_reputation.webp")} />
        </h2>
        <div className='row-1000'>
          <NumberInput
            useForm={inputForm}
            zeroByDefault={false}
            className={typesCommerce.includes(1) || typesCommerce.includes(9) ? "" : "w-0"}
            visible={typesCommerce.includes(1) || typesCommerce.includes(9)}
            label='tripadvisor (note de 1 à 5)'
            error={errors?.e_reputation?.note_tripadvisor?.message}
            name='e_reputation.note_tripadvisor'
            validation={rate5Validation}
          />
          <NumberInput
            useForm={inputForm}
            zeroByDefault={false}
            label='avis google (note de 1 à 5)'
            error={errors?.e_reputation?.note_google?.message}
            name='e_reputation.note_google'
            type='number'
            validation={rate5Validation}
          />
          <NumberInput
            useForm={inputForm}
            zeroByDefault={false}
            className={typesCommerce.includes(1) || typesCommerce.includes(9) ? "" : "w-0"}
            visible={typesCommerce.includes(1) || typesCommerce.includes(9)}
            label='the fork (note de 1 à 10)'
            error={errors?.e_reputation?.note_the_fork?.message}
            name='e_reputation.note_the_fork'
            type='number'
            validation={rate10Validation}
          />
          <NumberInput
            useForm={inputForm}
            zeroByDefault={false}
            className={typesCommerce.includes(5) ? "" : "w-0"}
            visible={typesCommerce.includes(5)}
            label='booking (note de 1 à 10)'
            error={errors?.e_reputation?.note_booking?.message}
            name='e_reputation.note_booking'
            type='number'
            validation={rate10Validation}
          />
          <NumberInput
            useForm={inputForm}
            zeroByDefault={false}
            label='autres (note de 1 à 5)'
            error={errors?.e_reputation?.note_autre?.message}
            name='e_reputation.note_autre'
            type='number'
            validation={rate5Validation}
          />
        </div>
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2 className='flex gap-sm'>
          diplômes & certifications
          <Tuto onClick={() => showTooltip("step_13_diplomes_certifications.webp")} />
        </h2>
        <Radio
          label='l’activité nécessite-t-elle un diplôme, une certification, une carte professionnelle ou une licence ? (hormis lic iii et iv et agréments buraliste)'
          error={errors?.diplome_certification?.id?.message}
          useForm={inputForm}
          content={[
            { value: 1, label: "Non" },
            { value: 2, label: "Oui" },
          ]}
          name='diplome_certification.id'
        />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>activité</h2>
        <Checkbox
          label={
            <>
              l'affaire possède des caractéristiques véritablement exceptionnelles ?
              <Tuto onClick={() => showTooltip("step_13_carac_hors_normes_checkbox.webp")} />
            </>
          }
          name='carac_hors_normes_checkbox'
          useForm={inputForm}
        />
        <Textarea
          visible={carac_hors_normes}
          label='décrivez-nous lesquelles'
          useForm={inputForm}
          name='carac_hors_normes'
          error={errors?.carac_hors_normes?.message}
          validation={{
            required: "Ce champ est obligatoire",
          }}
        />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2>notes et remarques générales</h2>
        <Textarea name='notes.note_step_13' useForm={inputForm} />
      </fieldset>
      <fieldset className='borderless-fieldset'>
        <h2 className='flex gap-sm'>
          coefficients de pondération <Tuto onClick={() => showTooltip("step_13_coeff_ponderation.webp")} />
        </h2>
        <div className='form-subtitle'>A appliquer sur les valeurs obtenues à l'issue de l'estimation initiale</div>
        <NumberInput
          useForm={inputForm}
          label="fourchette de volume d'activité moyen"
          name='coef_ponderation.coef_1'
          icon='percent'
          validation={{}}
          zeroByDefault={false}
        />
        <NumberInput
          useForm={inputForm}
          label='fourchette de rentabilité moyenne'
          name='coef_ponderation.coef_2'
          icon='percent'
          validation={{}}
          zeroByDefault={false}
        />
        <NumberInput
          useForm={inputForm}
          label="fourchette de rentabilité proportionnelle au volume d'activité"
          name='coef_ponderation.coef_3'
          icon='percent'
          validation={{}}
          zeroByDefault={false}
          {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
        />
      </fieldset>
    </form>
  );
}

export default Reputation;
