import "./CreditSimulator.css";
import React from "react";
import Breadcrumbs from "../../../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../../../components/molecules/banner/Banner";
import CreditSimulatorForm from "../../../components/forms/creditSimulatorForm/CreditSimulatorForm";
import { useAuth } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import ArrowSm from "../../../components/atoms/icons/general/arrowSm/ArrowSm";

function CreditSimulator() {
  const { getUuid } = useAuth();
  return (
    <>
      <section className='container page-container'>
        <Breadcrumbs
          routes={[
            {
              to: "/",
              name: "Accueil",
            },
            {
              to: "/simulateur-credit",
              name: "Tableau d'amortissement",
            },
          ]}
        />
        <Banner
          title='Simulateur de crédit'
          subtitle={
            getUuid() ? (
              <Link className='link-with-arrow' to='/mon-compte/mes-outils/mes-tableaux-d-amortissement'>
                <ArrowSm /> Accéder à mes tableaux d'amortissement
              </Link>
            ) : (
              "Tableau d'amortissement personnalisé pour votre plan de financement"
            )
          }
        />
        <CreditSimulatorForm />
      </section>
    </>
  );
}

export default CreditSimulator;
