import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ParcoursContext } from "../../../../../context/ParcoursContext";
import Radio from "../../../../molecules/formComponents/radio/Radio";
import Checkbox from "../../../../molecules/formComponents/checkbox/Checkbox";
import Select from "../../../../molecules/formComponents/select/Select";
import Textarea from "../../../../molecules/formComponents/textarea/Textarea";
import { max2250Validation } from "../../../../../utils/formValidation/FormValidation";
import { fetchDropdownValuesApi } from "../../../../../api/ParcoursApi";
import { formatObjectForPosting, getSelectValue } from "../../../../../utils/Utils";
import ZoneInfluence from "../../../../atoms/parcours/zoneInfluence/ZoneInfluence";
import { useWindowSize } from "@uidotdev/usehooks";
import Tuto from "../../../../atoms/icons/general/tuto/Tuto";

function Emplacement({ id, index }) {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    unregister,
    formState: { errors },
  } = useForm();
  const {
    showTooltip,
    handleTabs,
    hasLocalDependance,
    moveToNextStep,
    step,
    getStepToGo,
    setGoToStep,
    setStep,
    submitFormsFdc,
    postValues,
    estimationValues,
    fetchDropdownValues,
    fillInputsFromEstimationValues,
  } = useContext(ParcoursContext);

  const inputForm = { register, setValue, watch, unregister };
  const [dropdownsValues, setDropdownsValues] = useState({});
  const { width } = useWindowSize();
  const hasLocalDependanceWatcher = watch("dependance_local_commercial");

  useEffect(() => {
    fillDropdownsValues();
  }, []);

  useEffect(() => {
    submitFormsFdc.current[index] = handleSubmit(formSubmit);
  }, [step, estimationValues]);

  useEffect(() => {
    fillInputsFromEstimationValues(["emplacement"], setValue, estimationValues);

    setValue("dependance_local_commercial", !hasLocalDependance(estimationValues));
    setValue("notes.note_step_8", estimationValues?.notes?.note_step_8);
  }, [estimationValues, dropdownsValues]);

  async function formSubmit(values) {
    if (values.dependance_local_commercial) {
      values = {
        emplacement: {
          type_ville: values.emplacement.type_ville,
          qualite_secteur: { id: null },
          qualite_implantation_rue: { id: null },
        },
        notes: {
          note_step_8: values.notes.note_step_8,
        },
      };
    } else delete values.dependance_local_commercial;

    await postValues(formatObjectForPosting(values));

    if (getStepToGo() != 0) {
      setStep(getStepToGo());
      setGoToStep(0);
    } else moveToNextStep(index);
  }

  async function fillDropdownsValues() {
    setDropdownsValues(await fetchDropdownValues("qualite_secteur", "qualite_implantation_rue"));
  }

  return (
    <form ref={id} onSubmit={handleSubmit(formSubmit)} style={{ height: step == index ? "auto" : "0" }}>
      <fieldset className='borderless-fieldset'>
        <h2>Emplacement</h2>
        <Radio
          padding='2rem'
          label={
            <>
              zone d’influence de l’affaire
              <Tuto onClick={() => showTooltip("step_8_type_ville.webp")} />
            </>
          }
          useForm={inputForm}
          name='emplacement.type_ville.id'
          className='zone-influence-radio'
          error={errors?.emplacement?.type_ville?.id?.message}
          content={[
            {
              label: (
                <>
                  {width > 1200 && <br />}
                  Rayonnement local
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />}
                  Très petite ville et village
                  <br /> (-5.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='xs' className='parcours-zone-influence' />
                </>
              ),
              value: 1,
            },
            {
              label: (
                <>
                  Rayonnement {width > 1200 && <br />}
                  multicommunal
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />} Petite ville {width > 1200 && <br />}
                  (5.000 à 20.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='sm' className='parcours-zone-influence' />
                </>
              ),
              value: 2,
            },
            {
              label: (
                <>
                  Rayonnement {width > 1200 && <br />}
                  départemental
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />} Ville moyenne {width > 1200 && <br />}
                  (20.000 à 100.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='lg' className='parcours-zone-influence' />
                </>
              ),
              value: 3,
            },
            {
              label: (
                <>
                  Rayonnement {width > 1200 && <br />} régional&nbsp;ou&nbsp;+
                  <br /> <span className='emplacement-gold-text'>ou</span>
                  {width > 1200 && <br />} Grande ville {width > 1200 && <br />}(+100.000 hab)
                  {width > 1200 && <br />}
                  <ZoneInfluence size='xl' className='parcours-zone-influence' />
                </>
              ),
              value: 4,
            },
          ]}
        />
        <Checkbox
          label={
            <>
              l'activité ne dépend pas de locaux d'activité
              <Tuto onClick={() => showTooltip("step_8_dependance_local_commercial.webp")} />
            </>
          }
          name='dependance_local_commercial'
          useForm={inputForm}
          onFocus={() => {
            if (step == index + 1) setStep(index);
          }}
          onKeyDown={e => {
            if (e.keyCode === 9 && !e.shiftKey && !e.ctrlKey && e.target.checked) {
              e.preventDefault();
              handleSubmit(formSubmit)();
            }
          }}
        />
        <Select
          visible={!hasLocalDependanceWatcher}
          label={
            <>
              qualité de secteur et de clientèle dans l’agglomération
              <Tuto onClick={() => showTooltip("step_8_qualite_secteur.webp")} />
            </>
          }
          name='emplacement.qualite_secteur.id'
          useForm={inputForm}
          error={errors?.emplacement?.qualite_secteur?.id?.message}>
          {dropdownsValues?.qualite_secteur?.map(value => (
            <option key={value.id} value={value.id}>
              {value.description}
            </option>
          ))}
        </Select>
        <Select
          visible={!hasLocalDependanceWatcher}
          label={
            <>
              qualité d’implantation dans la rue
              <Tuto onClick={() => showTooltip("step_8_qualite_implantation_rue.webp")} />
            </>
          }
          name='emplacement.qualite_implantation_rue.id'
          useForm={inputForm}
          error={errors?.emplacement?.qualite_implantation_rue?.id?.messagee}>
          {dropdownsValues?.qualite_implantation_rue?.map(value => (
            <option key={value.id} value={value.id}>
              {value.description}
            </option>
          ))}
        </Select>
        <Textarea
          useForm={inputForm}
          name='notes.note_step_8'
          error={errors?.notes?.note_step_8?.message}
          label='notes et remarques'
          className='mt-md'
          validation={max2250Validation}
          {...handleTabs(step, index, () => handleSubmit(formSubmit)())}
        />
      </fieldset>
    </form>
  );
}

export default Emplacement;
